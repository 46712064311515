import React, { useState } from 'react';
import { getContactDtls } from './db';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

const CreditCard = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const handlePaymentClick = async () => { 

      if( props.total===0 ) {
        props.toPaidStatus({
          method:"זיכוי",
          last4digits:"0000",
          confirmationCode:"12345678",
        })
      } else { 

        setIsLoading(true);
        props.set_show_overlay(true);

        try {
            
            const [email, mobile]= await getContactDtls(props.userId)
            const receiptDtls = props.prepareReceiptDtls()
            const receiptDtlsJson = JSON.stringify(receiptDtls) 
            const encodedReceiptDtls = encodeURIComponent(receiptDtlsJson)

              const response = await axios.get(`${baseUrl}/CreatePaymentProcess.php`,{
              params: {
                total:props.total, 
                receiptDtls:encodedReceiptDtls, 
                email:email,
                mobile:mobile,                          
              }
            })

            if (response.data.status==='error') {
              throw new Error(response.data.message || 'Payment process failed');
            }

            const url = response.data.data.data.url;
        
            const popupWindow =window.open(url, '_popup', 'width=550, height=600, left=359, top=100');
            
            
            function messageListener(result) {

              if (result.origin === 'https://www.doroosonline.co.il') {

                  const data = result.data;
           
                  switch (data.action) {
                      case 'close':
                          
                          props.toPaidStatus({
                            method:"visa",
                            last4digits:"0000",
                            confirmationCode:"12345678",
                            status:data.status,
                            message:data.message,
                          })
                                    
                          if (popupWindow && !popupWindow.closed) {
                              popupWindow.close();
                          }
          
                          window.removeEventListener('message', messageListener);
                          break;
          
                      default:
                          props.alert("Received unexpected message: " + JSON.stringify(data),'ERROR');
                          window.removeEventListener('message', messageListener);
                          break;
                  }

                  props.set_show_overlay(false);
                  setIsLoading(false); 
              }
            }
          
          window.addEventListener('message', messageListener);
        
        } catch (error) {
            props.alert('Error initiating payment: '+error.message,'ERROR');
            props.set_show_overlay(false)
            setIsLoading(false);
        } 
      }
      
  };

  return (
    <button 
      type="button"  
      class="btn btn-outline-secondary btn-md check-btn" 
      onClick={handlePaymentClick}
      disabled={props.subTotal <= 0 || isLoading }
    >
      <span style={{ fontWeight: 'bold'}}>
        {isLoading ? '. . . Processing' :props.total===0?'השלם רכישת שיעורים':'עבור לתשלום'}
      </span>
    </button>
  );
};

export default CreditCard;

