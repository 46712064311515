
import React, { useState } from "react";
import { FcApproval } from "react-icons/fc";
import { MdCancel } from "react-icons/md";
import { FaRegEyeSlash } from "react-icons/fa";
import AcceptBtn from "./accept_btn";
import RejectBtn from "./reject_btn";
import '../Components/box.css';
import avatar from '../Assets/Images/img_avatar1.png';
import ProfileViewer from "./profile_viewer";


const TeacherConBox = (props) =>{
  
  const [status,setStatus]=useState("NO_RESPONSE");
  
  const confirm= async (jsonData) => {

    try {

      const data = JSON.parse(jsonData);

      await props.updateTchStatus(props.userId,data.comment,'APPROVED')
          
      if(status==="NO_RESPONSE")
        props.decreaseTchsNum() 
        
      setStatus("APPROVED") 

    } catch(error) {
      props.alert(error.message,'ERROR')
    }          
  }
//-------------------------------------------------------------------------------------------
  
  const deny= async (jsonData) => {

    try {

      const data = JSON.parse(jsonData);

      await props.updateTchStatus(props.userId,data.comment,'NOT_APPROVED')
          
      if(status==="NO_RESPONSE")
        props.decreaseTchsNum() 
        
      setStatus("NOT_APPROVED") 
                                            
    } catch(error) {

      props.alert(error.message,'ERROR')
    } 
               
  }
//-------------------------------------------------------------------------------------------

  return (
           
    <div class="item-box" style={{background:props.bgcolor}}>                                                      
      <div className="d-flex flex-wrap align-items-center justify-content-around" > 
                          
        <div class="px-3 py-3 py-lg-2" style={{width:"100px"}}>
          <span>{props.index}</span>                                                         
        </div>

        <div className="px-3 py-2 py-lg-2" style={{width:"100px"}}>                                    
          <span className="course-img">
            {props.photo!=null?
              <img src={props.photo}></img>
              :
              <img src={avatar}></img>
            }
          </span>                                           
        </div>

        <div class="px-3 py-3 py-lg-2" style={{width:"150px"}}>
          <ProfileViewer 
            btn_txt={props.userName} 
            userId={props.userId} 
            user={props.user} 
            adminId={props.adminId}
            alert={props.alert}
          />                                                                             
        </div>

        <div class="px-3 py-3 py-lg-2" style={{width:"150px"}}>
           <span>{props.id}</span>                                                                  
        </div>
                    
        <div class="px-3 py-3 py-lg-2" style={{width:"150px"}}>
          <span>{props.update}</span>
        </div>

        
        <div className="d-flex gap-4 px-3 py-4 py-lg-3">
          
          <AcceptBtn 
            status={status} 
            confirm={confirm}  
            title="אישור מורה" 
          />

          <RejectBtn 
            status={status} 
            deny={deny} 
            title="סירוב מורה"
          />  

          <span>{status==='NO_RESPONSE'?<FaRegEyeSlash color="red" size={30}/>:status==="APPROVED"?<FcApproval size={30}/>:<MdCancel color="red" size={30}/>}</span>           

        </div>                
      </div>                                                  
    </div>                                
  );
};



export default TeacherConBox;
