import React, { useState,useEffect } from "react";
import PaymentMethod from './payment_method'
import Checkout from './checkout'
import './payment_box.css'

const PaymentBox=(props)=>{ 
   
    const [paymentMethod,setPaymentMethod]=useState("visa");
    
    const getMethod=(method)=>{
        setPaymentMethod(method);
    }
    
    return ( 
        <div className="payment-box p-3 pt-2">

            <div>
                <PaymentMethod 
                    setMethod={getMethod}
                    total={props.total}
                    alert={props.alert}
                />
            </div>

            <div className="mt-4">
                <Checkout 
                    method={paymentMethod} 
                    toPaidStatus={props.toPaidStatus} 
                    total={props.total} 
                    subTotal={props.subTotal}
                    userId={props.userId}
                    set_show_overlay={props.set_show_overlay}
                    setErrorMsg={props.setErrorMsg}
                    prepareReceiptDtls={props.prepareReceiptDtls}
                    alert={props.alert}
                />
            </div>
        </div>               
    )  
}   
export default PaymentBox;  
