import  React, {useState,useEffect} from "react";
import {useLocation} from 'react-router-dom';
import PageTitle from "../Components/page_title";
import Pagination from '@mui/material/Pagination';
import SearchBar from "../Components/search_bar";
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import CourseBox from "../Components/course_box";
import ReactLoading from 'react-loading';
import axios from 'axios';
import Divider from '@mui/material/Divider';
import { Alert } from "@mui/material";
import AlertTitle from '@mui/material/AlertTitle';
import '../Components/alert.css';

const baseUrl = process.env.REACT_APP_BASE_URL;  
const win=4;

const SearchCourse = (props) => {
  const [page, setPage] = useState(1);
  const [schedule,setSchedule]=useState(false);
  const [courses,setCourses]=useState([]);
  const [fire,setFire]=useState(false);
  const [screenHeight, setScreenHeight] = useState(0);
  const location = useLocation();

  const [alert, setAlert] = useState({
    msg: null,
    status: 'SUCCESS',
  });

  useEffect(() => {
   
    setScreenHeight(window.innerHeight-200);
  
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');    
    const mode = params.get('mode');
    const tchId = params.get('tchId');
    
    if (location.state) {     
      search_by_code(location.state.code,location.state.mode);
    }
    else  if (code && mode){
      search_by_code(code,mode);
    } else if( tchId ){
      search_by_tchId(tchId);
    }
                          
  }, []);
//-------------------------------------------------------------------------------------------
  const goToTop = () => {
    window.scrollTo({
        top:0,
        behavior: 'auto',
        behavior: 'smooth',  
    })
  }
//-------------------------------------------------------------------------------------------

  const set_alert = (msg,status) =>{
    setAlert({
      msg:msg,
      status:status,
    })
    goToTop()
  }
//-------------------------------------------------------------------------------------------

  const search_by_code = async (code,mode) => {
    try {

      setFire(true); 

      const params = new URLSearchParams({
        code: code,
        mode: mode
      });

      let url = `${baseUrl}/search_courses.php`; 

      if (props.user !== "DEFAULT") {
        params.append('stdId', props.userId); 
      }

      const response = await axios.get(url, { params: params });

      if (response.data && response.data.status === 'success' && Array.isArray(response.data.data)) {

        setCourses(response.data.data);
      } else {

        setCourses([]);
        if (response.data.status === 'error') {
          throw new Error(`Error from server: ${response.data.message}`); 
        }
      }

    } catch (error) { 
      set_alert(`Caught an error: ${error.message}`,'ERROR');  
    } finally {
      setFire(false); 
      setPage(1); 
      setSchedule(prev => !prev); 
    }
  }  
//-------------------------------------------------------------------------------------------

  const search_by_tchId = async (tchId) => {
    try {

      setFire(true); 

      const params = new URLSearchParams({
        tchId: tchId,
      });

      let url = `${baseUrl}/search_courses.php`; 

      if (props.user !== "DEFAULT") {
        params.append('stdId', props.userId); 
      }

      const response = await axios.get(url, { params: params });

      if (response.data && response.data.status === 'success' && Array.isArray(response.data.data)) {

        setCourses(response.data.data);
      } else {

        setCourses([]);
        if (response.data.status === 'error') {
          throw new Error(`Error from server: ${response.data.message}`); 
        }
      }

    } catch (error) { 
      alert(`Caught an error: ${error.message}`);  
    } finally {
      setFire(false); 
      setPage(1); 
      setSchedule(prev => !prev); 
    }

  }
//-------------------------------------------------------------------------------------------

  const search = async (subId, catId, mode) => {
    try {

      setFire(true); 

      const params = new URLSearchParams({
        subId: subId,
        catId: catId,
        mode: mode
      });

      let url = `${baseUrl}/search_courses.php`; 

      if (props.user !== "DEFAULT") {
  
        params.append('stdId', props.userId); // Append student ID if not default user
      }

      const response = await axios.get(url, { params: params });

      if (response.data && response.data.status === 'success' && Array.isArray(response.data.data)) {

        setCourses(response.data.data);
      } else {

        setCourses([]);
        if (response.data.status === 'error') {
          throw new Error(`Error from server: ${response.data.message}`); 
        }
      }

    } catch (error) { 
      alert(`Caught an error: ${error.message}`);  
    } finally {
      setFire(false); 
      setPage(1); 
      setSchedule(prev => !prev); 
    }
  }
//-------------------------------------------------------------------------------------------

  const handleChangePage = (_,page)=> {
        
    if (page !== null){
      setPage(page)
      setSchedule((prev)=>!prev)
      goToTop()
    }
  
  }
//-------------------------------------------------------------------------------------------

  const updateCourseStatus = (index,code)=>{

    let x=(page-1)*win+index;
    let temp_state = [...courses];
    let temp_element = {...temp_state[x]};
    temp_element.reg =code;
    temp_state[x] = temp_element;
    setCourses(temp_state);
  }
//-------------------------------------------------------------------------------------------  

  return (
    
    <div className="container mb-4" style={{ minHeight: `${screenHeight}px` }}>
                      
        <div className="mt-4">
          <PageTitle text="חיפוש קורסים"/>
        </div>

        <div className="mt-4 ms-2">
          <SearchBar 
            search={search}
            alert={set_alert}
          />
        </div>

        <div className="mt-4">          
          <Divider></Divider>
        </div>

        <div className="d-flex flex-column gap-2 mt-4 position-relative">

          {fire?
            <div className="position-absolute top-50 start-50 translate-middle-x mt-5" style={{zIndex: "1"}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
            :
            null
          }

          {alert.msg != null ? (
            <div className="alert-container mb-2" dir="ltr">
              <Alert
                severity={alert.status.toLowerCase()} 
                variant="outlined"
                onClose={() => {
                  setAlert({ msg: null, status: 'SUCCESS' }); 
                }}
              >
                <AlertTitle>
                  <span className="bold-text">
                    {alert.status === 'SUCCESS' ? 'Success' : 'Error'}
                  </span>
                </AlertTitle>
                <span className="bold-text">{alert.msg}</span>
              </Alert>
            </div>
          ) : null}

          {courses.slice((page-1)*win,(page-1)*win+win).map((c,index)=>(                                                                                              
            <div>
              <CourseBox
                index={index}
                search={true}
                bgcolor={index%2===0?"rgb(240, 245, 240)":"rgb(243, 240, 245)"} 
                teacher={c.teacher} 
                rating={c.rating} 
                ratings={c.counter}
                subject={c.subject} 
                date={c.date} 
                photo={c.photo} 
                private={c.mode==="PRIVATE"?true:false}
                syllabus={c.syllabus}
                code={c.code}
                xcode={c.gcode!=null?c.gcode:c.pcode}
                reg={c.reg}                 
                updateCourseStatus={updateCourseStatus}                    
                schedule={schedule}
                stdId={props.userId}
                user={props.user}
                alert={set_alert}
              />
            </div>
          ))}
                             
        </div>
        
        <div  class="d-flex justify-content-end mt-4" dir="ltr">                     
          <Pagination count={Math.ceil(courses.length/win)} variant="outlined" shape="rounded" color="primary" page={page} onChange={handleChangePage}/>         
        </div>

    </div>
    
  );
};

export default SearchCourse;
