import { Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import '../Components/profile.css'
import BasicRating from "./rating";
import SelectProfilePic from "./select_profile_pic";
import avatar1 from "../Assets/Images/img_avatar1.png"
import MsgBox from "./msg_box";
import { FcApproval } from "react-icons/fc";
import { MdCancel } from "react-icons/md";
import { ImBlocked } from "react-icons/im"; 
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

const ProfileStatus=(props)=> {
    
  const [profileStatus, setProfileStatus] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(()=>{

    const fetchProfilePhoto = async () =>{

      try {
        const response = await axios.get(`${baseUrl}/get_user_profile_status.php`,{
          params: {                        
            userId:props.userId                                                           
          }
        })

        if( response.data.id > 0 ){ 

          setProfileStatus(response.data);         
        }
        else{
          setProfileStatus({})
        }
         
      } 
      catch (error) {

        props.alert(error.message,'ERROR') ;       
      } 

    }

    fetchProfilePhoto();


  },[])
//-------------------------------------------------------------------------------------------

  const close = () => {
    setOpen(false)
  };
//-------------------------------------------------------------------------------------------

  const changeProfilePic = (url) => {

    setProfileStatus(prevState => ({
      ...prevState,  
      url: url    
    }));
    props.refreshPicture(url)
  }
//-------------------------------------------------------------------------------------------

  return (
    <div className="d-flex flex-column align-items-center gap-4"> 

        <div>
          {open?
            <MsgBox  closeBtn={true} title="הערות" content={profileStatus.comment} close={close} /> 
            :null
          }
        </div>

        <div className="circle-frame">
            <img 
                src={profileStatus.url!=null?profileStatus.url:avatar1} 
                alt="Profile Photo"
                className="profile-photo" 
            />
        </div>
        
        <div className="mb-4 mb-md=0">
          <SelectProfilePic 
            userId={props.userId} 
            changeProfilePic={changeProfilePic}
            alert={props.alert}
          />
        </div>

        { profileStatus.user==='TCH' || profileStatus.user==='SCH'?
          <>
            <div dir="ltr">        
              <BasicRating 
                value={profileStatus.t_rating != null?profileStatus.t_rating:profileStatus.c_rating} 
                ratings={profileStatus.t_counter != null?profileStatus.t_counter:profileStatus.c_counter}  
                readOnly={true}  
                alert={props.alert}           
              />              
            </div>

            { 
              profileStatus.blocked?
                <ImBlocked color="red" size={25}/>
              : profileStatus.enabled?
                <FcApproval size={25}/>
              : 
                <MdCancel color="red" size={25}/>
            }

            <button type="button" class="btn btn-warning btn-sm" style={{width:"60px"}} 
              onClick={() => {            
                setOpen(true);                
              }}                                                                
            >
              הערות
            </button>
          </>
          :
          null
        }                                                                                                                                                 
    </div>      
  );
}

export default ProfileStatus;
