import React, { useState } from "react";
import { Modal, Form } from 'react-bootstrap';
import Button from '@mui/material/Button'; 
import AssuredWorkloadOutlinedIcon from '@mui/icons-material/AssuredWorkloadOutlined';
import DatePicker from "react-datepicker"; 
import dateFormat, { masks } from "dateformat";
import * as Yup from 'yup';
import "react-datepicker/dist/react-datepicker.css";
import './popup_modals.css';
import '../Components/search_by_date.css';
import '../Components/forms.css'

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
    taxRate: Yup.number()
        .required('Tax rate is required')
        .positive('Tax rate must be positive')
        .min(1, 'Tax rate must be at least 1%')
        .max(100, 'Tax rate cannot be more than 100%')
        .typeError('Please enter a valid tax rate'),
    expDate: Yup.date()
        .required('Expiration date is required')
        .typeError('Please enter a valid expiration date'),
    comment: Yup.string()
        .min(5, 'Comment must be at least 5 characters')
        .required('Comment is required'),
});

const TaxDeduction = (props) => {
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState({});
    const [comment, setComment] = useState("");
    const [expDate, setExpDate] = useState(new Date());
    const [taxRate, setTaxRate] = useState("");  

    // Handle input changes for comment
    const handleChangeComment = event => {
        setComment(event.target.value);
    };

    // Handle input changes for tax rate
    const handleChangeTaxRate = event => {
        setTaxRate(event.target.value);
    };

    // Validate form data using Yup
    const validateData = async () => {
        const formData = { taxRate, expDate, comment };
        try {
            await validationSchema.validate(formData, { abortEarly: false });
            setErrors({});
            return true;
        } catch (err) {
            const newErrors = err.inner.reduce((acc, { path, message }) => {
                acc[path] = message;
                return acc;
            }, {});
            setErrors(newErrors);
            return false;
        }
    };

    // Form submission handler
    const handleSubmit = async (event) => {

        event.preventDefault();
    
        const isValid = await validateData();
        if (isValid) {

            const data = {
                docType:'Tax_Rate',
                expDate:expDate, 
                taxRate:taxRate,
                comment: comment
            };
            
            // Convert to JSON string
            const jsonData = JSON.stringify(data);

            props.confirm(jsonData);
            closeConfirmationBox();
        } 
    };

    // Open the confirmation modal
    const openConfirmationBox = () => {
        setShow(true);
    };

    // Close the confirmation modal
    const closeConfirmationBox = () => {
        setShow(false);
        setErrors({});
    };

    return (
        <div>        
            {props.status !== "APPROVED" ? (
                <button type="button" class="btn btn-outline-success btn-sm" style={{borderRadius:"15px",width:"85px"}} onClick={openConfirmationBox}>מאשר</button>
            ) : (
                <button type="button" class="btn btn-outline-success btn-sm" style={{borderRadius:"15px",width:"85px"}} disabled>מאשר</button>
            )}

            <Modal 
              show={show}  
              size="md"
              aria-labelledby="contained-modal-title-vcenter"            
            >  
                <Modal.Header style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}>
                    <div className="d-flex gap-2 align-items-center">
                        <div><AssuredWorkloadOutlinedIcon fontSize="large"/></div>
                        <div className="mt-1"><h5>אישור ניכוי מס במקור</h5></div>
                    </div>   
                    <button
                        type="button"
                        className="custom-modal-close"
                        aria-label="Close"
                        onClick={closeConfirmationBox}
                    >
                        &times;  {/* Close "X" icon */}
                    </button>                                           
                </Modal.Header>  
                
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <div className="d-flex gap-4 mt-4">
                            <div className="label-100-wrap" style={{ width: '150px' }}>
                                <label className="form-label label-100" htmlFor="tax_rate">שיעור ניכוי מס %</label>
                                <input
                                    type="number"
                                    className={`form-control ${errors.taxRate ? 'invalid' : ''}`}
                                    id="tax_rate"
                                    name="tax_rate"
                                    value={taxRate}
                                    onChange={handleChangeTaxRate}                                
                                    step="0.01"
                                    min="0"
                                    max="100"
                                />
                                {errors.taxRate && <p className="error">{errors.taxRate}</p>}
                            </div>

                            <div className="label-100-wrap">
                                <label className="form-label label-100" htmlFor="exp_date">תאריך פקיעת תוקף</label>
                                <div id="exp_date" dir="rtl">              
                                    <DatePicker 
                                        wrapperClassName={`date-picker ${errors.expDate ? 'invalid' : ''}`} 
                                        selected={expDate} 
                                        onChange={setExpDate} 
                                        popperPlacement="bottom-end" 
                                        dateFormat="yyyy-MM-dd"                                                 
                                    />   
                                    {errors.expDate && <p className="error">{errors.expDate}</p>}                   
                                </div>
                            </div>
                        </div>

                        <div className="label-100-wrap mt-4">                                           
                            <label className="form-label label-100" htmlFor="comment">הערות</label>                                                                                           
                            <div>                    
                                <textarea 
                                    className={`form-control ${errors.comment ? 'invalid' : ''}`} 
                                    id="comment" 
                                    name="comment" 
                                    rows="3" 
                                    value={comment} 
                                    onChange={handleChangeComment} 
                                />
                                {errors.comment && <p className="error">{errors.comment}</p>}
                            </div>   
                        </div>                                                                                                                    
                    </Modal.Body>  

                    <Modal.Footer>
                        <div className="d-flex gap-3">                         
                            <Button variant="contained" color="error" size="small" onClick={closeConfirmationBox}>ביטול</Button>  
                            <Button variant="contained" color="success" size="small" type="submit">אישור</Button>                         
                        </div>
                    </Modal.Footer> 
                </Form>        
            </Modal> 
        </div>
    );
};

export default TaxDeduction;
