import React, { useState,useEffect } from "react";
import axios from 'axios';
import {Modal,Table} from 'react-bootstrap'; 
import Button from '@mui/material/Button';
import AddScheduleLine from "./add_schedule_line";
import { FaRegClipboard  } from 'react-icons/fa';
import './popup_modals.css';
import '../Components/forms.css'

const baseUrl = process.env.REACT_APP_BASE_URL;

const SetCoursePro=(props)=>{ 

    const [lines,setLines]=useState([]);       
    const [show,setShow]=useState(false);
    const [refresh,setRefresh]=useState(false);

    useEffect(()=>{

      const fetchScheduleLines = async () => {
        try{
            const response = await axios.get(`${baseUrl}/schedule.php?code=${props.code}`)
                                
            if(Array.isArray(response.data) && response.data.length ){          
              setLines(response.data)             
            }
            else{                       
              setLines([]);                         
            }                              
        } 
        catch (error) {
          alert(error.message,'ERROR') ;         
        }        
      }

      fetchScheduleLines();

      if(!props.link)
          setShow(true);
          
    },[]);
//-------------------------------------------------------------------------------------------

  const alert=(msg,status)=>{
    closeCourseProgram()
    props.alert(msg,status)
  }
//------------------------------------------------------------------------------------------- 

  function openCourseProgram(){ 
    setShow(true)
  };
//-------------------------------------------------------------------------------------------

  function closeCourseProgram(){ 
    setShow(false)
    if(!props.link)
      props.resetMenu(0);
  };
//-------------------------------------------------------------------------------------------

  const addLine=(line)=>{

    if(lines.length<5){
      setLines((prevArr)=> [...prevArr,line]);
      setRefresh(prev=>prev=!prev)
      props.refreshSchedule()
    }
    
  }
//-------------------------------------------------------------------------------------------

  const removeLine= async (code,num,hour,id)=> {

    try{
        const response = await axios.get(`${baseUrl}/delete_line.php`,{
          params: {
            code:code,
            time:hour,
            lessons_num:num
          }
        })

        if( response.data.id > 0 ){
          const newList = lines.filter((line) => line.id !== id);
          setLines(newList);
          setRefresh(prev=>prev=!prev)
          props.refreshSchedule()
        }           
        else
          alert("מחיקת שורה מלוח זמנים נכשלה,נסה שנית!!",'ERROR')
        
    } 
    catch (error) {
      alert(error.message,'ERROR') ;
    }
  }
//-------------------------------------------------------------------------------------------
    
  return (  
    <div>        
      {props.link?<a href="#"  onClick={openCourseProgram}>{props.btn_txt}</a>:null}
      <Modal 
            show={show}  
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
      >  
        <Modal.Header style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}>
          <div className="d-flex gap-2 align-items-center">
            <div><FaRegClipboard size={30} /></div>
            <div className="mt-1"><h5>תכנון מערכת שעות</h5></div>
          </div>
          <button
              type="button"
              className="custom-modal-close"
              aria-label="Close"
              onClick={closeCourseProgram}
          >
              &times;  {/* Close "X" icon */}
          </button>
        </Modal.Header>  
        <Modal.Body>
          <div className="container mt-4 p-2">
            
            <AddScheduleLine 
              add={addLine} 
              code={props.code} 
              tchId={props.tchId} 
              refresh={refresh}
              alert={alert}
            />

            <div class="mt-4">                        
              <Table hover >
                <thead className=" text-center">
                  <tr>
                    <th>#</th>
                    <th>יום</th>
                    <th>שעה</th>
                    <th>מספר שיעורים</th>
                    <th>מחיקה</th>
                  </tr>
                </thead>
                <tbody className=" text-center">
                  {lines.length>0?
                    lines.map((line,index)=>(
                      <tr>
                        <td>{index+1}</td>
                        <td>{line.day}</td>
                        <td>{line.time}</td>
                        <td>{line.num}</td>
                        <td><button type="button" class="btn-close" onClick={() => removeLine(props.code,line.num,line.hour,line.id)}></button></td>
                      </tr>                          
                    ))
                    :null
                  } 
                </tbody>                                                                                                                                 
              </Table>
            </div>

          </div> 

        </Modal.Body>  
        <Modal.Footer>  
          <div className="d-flex gap-3 me-2 mb-2">            
              <Button variant="contained"  color="error"  size="small" onClick={closeCourseProgram}>סגור</Button>
          </div>
        </Modal.Footer>  
      </Modal> 
    </div>        
  )  
}   
export default SetCoursePro;  
