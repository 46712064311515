import React, { useState,useEffect } from "react"; 
import '../Components/course_schedule.css';
import ReplayToMsg from "./replay_to_msg";

const DisplayMsg=(props)=>{
  
  const paragraphs = props.msg.split('<split>')
        
  return ( 
    <>        
      <div className="mt-2 mb-3">

        <div className="d-flex justify-content-start">
          <span className="schedule">תוכן ההודעה:</span>
        </div>

        <div className="shadow-sm rounded p-3 mt-1">        
          {
            paragraphs.map((paragraph, index) => (                           
              paragraph.split('<br>').map((line) => (
                <div style={index%2===1?{color:"blue"}:{color:"gray"}} dangerouslySetInnerHTML={{ __html: line }} />               
              ))                                                      
            )).reduce((acc, curr) => [acc, <br key={Math.random()} />, curr])
          }                          
        </div> 

        <div className="mt-3">
          <ReplayToMsg 
            link={true}  
            msg={props.msg}
            index={props.index}
            msgId={props.msgId} 
            email={props.email} 
            subject={props.subject}
            status={props.status}
            updateMessage={props.updateMessage}
            updateMsgStatus={props.updateMsgStatus}
            date={props.date}
            alert={props.alert}
          />            
        </div>

      </div>           
    </>      
  )  
} 
  
export default DisplayMsg;  
