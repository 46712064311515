import React from 'react';
import { useCountdown } from './use_count_down';
import { GiAlarmClock } from 'react-icons/gi';

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div dir='ltr' style={{fontSize:"12px"}}>            
      {days} : {hours} : {minutes} : {seconds}                            
    </div>
  );
};

const ExpiredCountdownTimer = (props) => {
  const [days, hours, minutes, seconds] = useCountdown(props.targetDate);

  if (days + hours + minutes + seconds > 0) {
    return (      
      <div className="d-flex align-items-center gap-2">                                                
            <span><GiAlarmClock size={19}/></span>
          <span className='mt-2'><ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds}/></span>                                                                                                                
      </div>                                               
    );
  }
  else {
    return("");    
  }
};

export default ExpiredCountdownTimer;
