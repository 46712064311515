import React, { useState } from "react";
import { Modal, Form } from 'react-bootstrap';
import Button from '@mui/material/Button'; 
import * as Yup from 'yup';
import "react-datepicker/dist/react-datepicker.css";
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import './popup_modals.css';
import '../Components/forms.css'

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
    bankCode: Yup.number()
        .required('Bank code is required')
        .typeError('Please enter a valid bank code')
        .positive('Bank code must be positive'),
    branchCode: Yup.number()
        .required('Branch code is required')
        .typeError('Please enter a valid branch code')
        .positive('Branch code must be positive'),
    accountNumber: Yup.string()
        .required('Account number is required')
        .min(6, 'Account number must be at least 6 digits')
        .max(16, 'Account number cannot exceed 16 digits'),
    comment: Yup.string()
        .min(5, 'Comment must be at least 5 characters')
        .required('Comment is required'),
});

const BankAccountDetails = (props) => {
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState({});
    const [bankCode, setBankCode] = useState("");
    const [branchCode, setBranchCode] = useState("");
    const [accountNumber, setAccountNumber] = useState("");  
    const [comment, setComment] = useState("");  // Comment state


    // Handle input changes for bank code
    const handleChangeBankCode = event => {
        setBankCode(event.target.value);
    };

    // Handle input changes for branch code
    const handleChangeBranchCode = event => {
        setBranchCode(event.target.value);
    };

    // Handle input changes for account number
    const handleChangeAccountNumber = event => {
        setAccountNumber(event.target.value);
    };

    // Handle input changes for comment
    const handleChangeComment = event => {
        setComment(event.target.value);
    };

    // Validate form data using Yup
    const validateData = async () => {
        const formData = { bankCode, branchCode, accountNumber, comment };
        try {
            await validationSchema.validate(formData, { abortEarly: false });
            setErrors({});
            return true;
        } catch (err) {
            const newErrors = err.inner.reduce((acc, { path, message }) => {
                acc[path] = message;
                return acc;
            }, {});
            setErrors(newErrors);
            return false;
        }
    };

    // Form submission handler
    const handleSubmit = async (event) => {
        event.preventDefault();
        const isValid = await validateData();
        if (isValid) {

            const data = {
                docType:'Bank_Account',
                bank_code: bankCode,
                branch_code: branchCode,
                account_number: accountNumber,
                comment: comment
            };
            
            // Convert to JSON string
            const jsonData = JSON.stringify(data);
            
            props.confirm(jsonData); 
            closeConfirmationBox();
        } 
    };

    // Open the confirmation modal
    const openConfirmationBox = () => {
        setShow(true);
    };

    // Close the confirmation modal
    const closeConfirmationBox = () => {
        setShow(false);
        setErrors({});
    };

    return (
        <div>        
            {props.status !== "APPROVED" ? 
                <button type="button" class="btn btn-outline-success btn-sm" style={{borderRadius:"15px",width:"85px"}} onClick={openConfirmationBox}>מאשר</button>
             : 
                <button type="button" class="btn btn-outline-success btn-sm" style={{borderRadius:"15px",width:"85px"}} disabled>מאשר</button>
            }

            <Modal 
              show={show}  
              size="md"
              aria-labelledby="contained-modal-title-vcenter"            
            >  
                <Modal.Header style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}>
                    <div className="d-flex gap-2 align-items-center">
                        <div><CreditScoreOutlinedIcon fontSize="large"/></div>
                        <div className="mt-1"><h5>אישור חשבון בנק</h5></div>
                    </div>   
                    <button
                        type="button"
                        className="custom-modal-close"
                        aria-label="Close"
                        onClick={closeConfirmationBox}
                    >
                        &times;  {/* Close "X" icon */}
                    </button>                                           
                </Modal.Header>  
                
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <div className="d-flex gap-3 mt-3">
                            {/* Account Number Input */}
                            <div className="label-100-wrap">
                                <label className="form-label label-100" htmlFor="account_number">מס' חשבון</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.accountNumber ? 'invalid' : ''}`}
                                    id="account_number"
                                    name="account_number"
                                    value={accountNumber}
                                    onChange={handleChangeAccountNumber}                                
                                />
                                {errors.accountNumber && <p className="error">{errors.accountNumber}</p>}
                            </div>

                            {/* Branch Code Input */}
                            <div className="label-100-wrap">
                                <label className="form-label label-100" htmlFor="branch_code">מס' סניף</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.branchCode ? 'invalid' : ''}`}
                                    id="branch_code"
                                    name="branch_code"
                                    value={branchCode}
                                    onChange={handleChangeBranchCode}                                
                                />
                                {errors.branchCode && <p className="error">{errors.branchCode}</p>}
                            </div> 

                            {/* Bank Code Input */}
                            <div className="label-100-wrap">
                                <label className="form-label label-100" htmlFor="bank_code">מס' בנק</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.bankCode ? 'invalid' : ''}`}
                                    id="bank_code"
                                    name="bank_code"
                                    value={bankCode}
                                    onChange={handleChangeBankCode}                                
                                />
                                {errors.bankCode && <p className="error">{errors.bankCode}</p>}
                            </div>    

                        </div>

                        {/* Comment Area */}
                        <div className="label-100-wrap mt-4">                                           
                            <label className="form-label label-100" htmlFor="comment">הערות</label>                                                                                           
                            <div>                    
                                <textarea 
                                    className={`form-control ${errors.comment ? 'invalid' : ''}`} 
                                    id="comment" 
                                    name="comment" 
                                    rows="3" 
                                    value={comment} 
                                    onChange={handleChangeComment} 
                                />
                                {errors.comment && <p className="error">{errors.comment}</p>}
                            </div>   
                        </div>
                        
                    </Modal.Body>  

                    <Modal.Footer>
                        <div className="d-flex gap-3">                         
                            <Button variant="contained" color="error" size="small" onClick={closeConfirmationBox}>ביטול</Button>  
                            <Button variant="contained" color="success" size="small" type="submit">אישור</Button>                         
                        </div>
                    </Modal.Footer> 
                </Form>        
            </Modal> 
        </div>
    );
};

export default BankAccountDetails; 
