import React, { useState } from "react";
import { Modal, Form } from 'react-bootstrap';
import Button from '@mui/material/Button'; 
import * as Yup from 'yup';
import "react-datepicker/dist/react-datepicker.css";
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import './popup_modals.css';
import '../Components/forms.css';

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
    businessName: Yup.string()
        .required('Business name is required')
        .min(3, 'Business name must be at least 3 characters'),
    businessCode: Yup.string()
        .required('Business code is required')
        .matches(/^[0-9]{9}$/, 'Business code must be exactly 9 digits'), // Ensures exactly 9 digits
    comment: Yup.string()
        .required('Comment is required')
        .min(5, 'Comment must be at least 5 characters')
});

const BusinessDetails = (props) => {
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState({});
    const [businessName, setBusinessName] = useState("");
    const [businessCode, setBusinessCode] = useState(""); 
    const [comment, setComment] = useState("");  

    const handleChangeBusinessName = event => {
        setBusinessName(event.target.value);
    };

    const handleChangeBusinessCode = event => {
        setBusinessCode(event.target.value);
    };

    const handleChangeComment = event => {
        setComment(event.target.value);
    };

    const validateData = async () => {
        const formData = { businessName, businessCode, comment };
        try {
            await validationSchema.validate(formData, { abortEarly: false });
            setErrors({});
            return true;
        } catch (err) {
            const newErrors = err.inner.reduce((acc, { path, message }) => {
                acc[path] = message;
                return acc;
            }, {});
            setErrors(newErrors);
            return false;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const isValid = await validateData();
        if (isValid) {
            const data = {
                docType: 'business',
                businessName: businessName,
                businessCode: businessCode,
                comment: comment
            };

            // Convert to JSON string
            const jsonData = JSON.stringify(data);

            props.confirm(jsonData); 
            closeConfirmationBox();
        } 
    };

    const openConfirmationBox = () => {
        setShow(true);
    };

    const closeConfirmationBox = () => {
        setShow(false);
        setErrors({});
    };

    return (
        <div>        
            {props.status !== "APPROVED" ? 
                <button type="button" className="btn btn-outline-success btn-sm" style={{borderRadius:"15px",width:"85px"}} onClick={openConfirmationBox}>מאשר</button>
             : 
                <button type="button" className="btn btn-outline-success btn-sm" style={{borderRadius:"15px",width:"85px"}} disabled>מאשר</button>
            }

            <Modal 
              show={show}  
              size="md"
              aria-labelledby="contained-modal-title-vcenter"            
            >  
                <Modal.Header style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}>
                    <div className="d-flex gap-2 align-items-center">
                        <div><WorkOutlineIcon fontSize="large"/></div>
                        <div className="mt-1"><h5>אישור עוסק מורשה</h5></div>
                    </div>   
                    <button
                        type="button"
                        className="custom-modal-close"
                        aria-label="Close"
                        onClick={closeConfirmationBox}
                    >
                        &times;  {/* Close "X" icon */}
                    </button>                                           
                </Modal.Header>  
                
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <div className="d-flex gap-3 mt-3">
                          
                            <div className="label-100-wrap">
                                <label className="form-label label-100" htmlFor="business_name">כינוי העסק</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.businessName ? 'invalid' : ''}`}
                                    id="business_name"
                                    name="business_name"
                                    value={businessName}
                                    onChange={handleChangeBusinessName}                                
                                />
                                {errors.businessName && <p className="error">{errors.businessName}</p>}
                            </div>
                            
                            <div className="label-100-wrap">
                                <label className="form-label label-100" htmlFor="business_code">מספר עוסק</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.businessCode ? 'invalid' : ''}`}
                                    id="business_code"
                                    name="business_code"
                                    value={businessCode}
                                    onChange={handleChangeBusinessCode}                                
                                />
                                {errors.businessCode && <p className="error">{errors.businessCode}</p>}
                            </div> 
                        </div>

                        {/* Comment Area */}
                        <div className="label-100-wrap mt-4">                                           
                            <label className="form-label label-100" htmlFor="comment">הערות</label>                                                                                           
                            <div>                    
                                <textarea 
                                    className={`form-control ${errors.comment ? 'invalid' : ''}`} 
                                    id="comment" 
                                    name="comment" 
                                    rows="3" 
                                    value={comment} 
                                    onChange={handleChangeComment} 
                                />
                                {errors.comment && <p className="error">{errors.comment}</p>}
                            </div>   
                        </div>
                        
                    </Modal.Body>  

                    <Modal.Footer>
                        <div className="d-flex gap-3">                         
                            <Button variant="contained" color="error" size="small" onClick={closeConfirmationBox}>ביטול</Button>  
                            <Button variant="contained" color="success" size="small" type="submit">אישור</Button>                         
                        </div>
                    </Modal.Footer> 
                </Form>        
            </Modal> 
        </div>
    );
};

export default BusinessDetails;
