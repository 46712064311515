
import React, { useState } from "react";
import { FcApproval } from "react-icons/fc";
import { MdCancel } from "react-icons/md";
import { FaRegEyeSlash } from "react-icons/fa";
import DocViewer from "./docs_viewer";
import '../Components/box.css'
import RejectBtn from "./reject_btn";
import AcceptDocs from "./accept_btn";
import TeacherIdentityDetails from "./accept_tch_id";
import BankAccountDetails from "./bank_dtls";
import TaxRateConfirmation from "./confirm_tax_rate";
import BusinessDetails from "./accept_business";
import TaxDeduction from "./tax_deduction";

const DocumentConBox = (props) =>{
  const [status,setStatus]=useState(props.status);
  
  const confirm= async (jsonData) => {

    try{

      await props.updateDocStatus(props.userId,props.docId,props.user,jsonData,'APPROVED')
           
      if(status==="NO_RESPONSE")
        props.decreaseDocsNum() 
        
      setStatus("APPROVED") 

    } catch (error) {
        
      props.alert("Error: " + error.message,'ERROR');  
    }                                      
             
  }
//-------------------------------------------------------------------------------------------
  
  const deny= async (jsonData) => {

    try{

      await props.updateDocStatus(props.userId,props.docId,props.user,jsonData,'NOT_APPROVED')
           
      if(status==="NO_RESPONSE")
        props.decreaseDocsNum() 
        
      setStatus("NOT_APPROVED") 

    } catch (error) {
        
       props.alert("Error: " + error.message,'ERROR');  
    }                       
        
  }
//-------------------------------------------------------------------------------------------

  return (
           
    <div class="item-box" style={{background:props.bgcolor}}>                                                      
      <div className="d-flex flex-wrap align-items-center justify-content-around" > 
                          
        <div class="px-3 py-3 py-lg-2" style={{width:"100px"}}>
          <span>{props.index}</span>                                                         
        </div>
        
        <div class="px-3 py-3 py-lg-1" style={{width:"150px"}}>
            <span>
              <DocViewer 
                btn_txt={props.docType} 
                doc={props.file}
                alert={props.alert}
              />
            </span>                                                                  
        </div>
        
        <div class="px-3 py-3 py-lg-2" style={{width:"150px"}}>
          <span>{props.date}</span>
        </div>

        <div class="px-3 py-3 py-lg-2" style={{width:"150px"}}>
           <span>{props.userName}</span>                                                                  
        </div>

        <div className="d-flex gap-4 px-3 py-4 py-lg-3">
         
          {(() => {
            if(props.recipient==13){
              switch (props.docType) {
                
                case 'ת"ז':
                    return <TeacherIdentityDetails status={status} confirm={confirm} alert={props.alert} />;

                case 'אישור ניהול חשבון':
                    return <BankAccountDetails status={status} confirm={confirm} alert={props.alert} />;
                case 'תיאום מס':
                    return <TaxRateConfirmation status={status} confirm={confirm} alert={props.alert} />;

                case 'תעודת עוסק פטור/מורשה':
                  return <BusinessDetails status={status} confirm={confirm} alert={props.alert} />;

                case 'אישור נכוי מס במקור':
                  return <TaxDeduction status={status} confirm={confirm} alert={props.alert} />;

                default:
                  return <AcceptDocs status={status} confirm={confirm} alert={props.alert} />;
              }
            } else {
              return <AcceptDocs status={status} confirm={confirm} title='אישור מסמך' alert={props.alert} />; 
            }
          })()}

          <RejectBtn status={status} deny={deny} title='דחיית מסמך' alert={props.alert} />   

          <span>{
              status==='NO_RESPONSE'?
                <FaRegEyeSlash color="red" size={30}/>
              :status==="APPROVED"?
                <FcApproval size={30}/>
              :
                <MdCancel color="red" size={30}/>
            }
          </span>           
        </div>                
      </div>                                                  
    </div>                                
  );
};

export default DocumentConBox;
