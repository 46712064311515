import {useEffect} from "react";
import AWS from "aws-sdk";

const useEmailSender = () => { 
                       
  useEffect(()=>{

      AWS.config.update({
          accessKeyId: 'AKIAZTIOBN2F5A4NYEGO',
          secretAccessKey: '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',
          region: 'eu-west-2'
        });
  },[]);
//------------------------------------------------------------------------------------------- 

  const sendEmail = (to, subject, body) => {
      
      return new Promise((resolve, reject) => {
              
          const params = {
              
              Destination: {
                  ToAddresses: [to]
              },

              Message: {
                  Body: {
                      Html: {
                          Data: body
                      }
                  },
                  Subject: {
                      Data: subject
                  },                                                                               
              },
              
              Source: 'no_reply_doroos@doroosonline.co.il',                           
          }

          const ses = new AWS.SES()
          
          ses.sendEmail(params,(error, data) => {
              if (error) {            
                  reject(error)
              } else {
                  resolve(data)                    
              }           
          })
      })           
  }

  return { sendEmail }

}
export default useEmailSender;

