import React, { useState } from "react";
import { Modal, Form } from 'react-bootstrap';
import Button from '@mui/material/Button'; 
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import * as Yup from 'yup';
import './popup_modals.css';
import '../Components/forms.css'

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
    teacherID: Yup.string()
        .matches(/^\d{9}$/, 'Teacher ID must be exactly 9 digits')
        .required('Teacher ID is required'),
    comment: Yup.string()
        .min(5, 'Comment must be at least 5 characters')
        .required('Comment is required'),
});

const TeacherIdentityDetails = (props) => {
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState({});
    const [teacherID, setTeacherID] = useState("");
    const [comment, setComment] = useState("");  // Comment state

    // Handle input changes for teacher ID
    const handleChangeTeacherID = event => {
        setTeacherID(event.target.value);
    };

    // Handle input changes for comment
    const handleChangeComment = event => {
        setComment(event.target.value);
    };

    // Validate form data using Yup
    const validateData = async () => {
        const formData = { teacherID, comment };
        try {
            await validationSchema.validate(formData, { abortEarly: false });
            setErrors({});
            return true;
        } catch (err) {
            const newErrors = err.inner.reduce((acc, { path, message }) => {
                acc[path] = message;
                return acc;
            }, {});
            setErrors(newErrors);
            return false;
        }
    };

    // Form submission handler
    const handleSubmit = async (event) => {
        event.preventDefault();
        const isValid = await validateData();
        if (isValid) {

            const data = {
                docType:'Identity',
                teacherID:teacherID,
                comment: comment
            };
            
            // Convert to JSON string
            const jsonData = JSON.stringify(data);

            props.confirm(jsonData); 
            closeConfirmationBox();
        } 
    };

    // Open the confirmation modal
    const openConfirmationBox = () => {
        setShow(true);
    };

    // Close the confirmation modal
    const closeConfirmationBox = () => {
        setShow(false);
        setErrors({});
    };

    return (
        <div>        
            {props.status !== "APPROVED" ? 
                <button type="button" class="btn btn-outline-success btn-sm" style={{borderRadius:"15px",width:"85px"}} onClick={openConfirmationBox}>מאשר</button>
             : 
                <button type="button" class="btn btn-outline-success btn-sm" style={{borderRadius:"15px",width:"85px"}} disabled>מאשר</button>
            }

            <Modal 
              show={show}  
              size="md"
              aria-labelledby="contained-modal-title-vcenter"            
            >  
                <Modal.Header style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}>
                    <div className="d-flex gap-2 align-items-center">
                        <div><PersonOutlinedIcon fontSize="large"/></div>
                        <div className="mt-1"><h5>אישור תעודת זהות</h5></div>
                    </div>   
                    <button
                        type="button"
                        className="custom-modal-close"
                        aria-label="Close"
                        onClick={closeConfirmationBox}
                    >
                        &times;  {/* Close "X" icon */}
                    </button>                                           
                </Modal.Header>  
                
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <div className="d-flex flex-column gap-3 mt-3">
                            {/* Teacher Identity Input */}
                            <div className="label-100-wrap">
                                <label className="form-label label-100" htmlFor="teacher_id">ת"ז</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.teacherID ? 'invalid' : ''}`}
                                    id="teacher_id"
                                    name="teacher_id"
                                    value={teacherID}
                                    onChange={handleChangeTeacherID}   
                                    style={{width:"200px"}}                            
                                />
                                {errors.teacherID && <p className="error">{errors.teacherID}</p>}
                            </div>

                            {/* Comment Area */}
                            <div className="label-100-wrap mt-4">                                           
                                <label className="form-label label-100" htmlFor="comment">הערות</label>                                                                                           
                                <div>                    
                                    <textarea 
                                        className={`form-control ${errors.comment ? 'invalid' : ''}`} 
                                        id="comment" 
                                        name="comment" 
                                        rows="3" 
                                        value={comment} 
                                        onChange={handleChangeComment} 
                                    />
                                    {errors.comment && <p className="error">{errors.comment}</p>}
                                </div>   
                            </div>
                        </div>
                        
                    </Modal.Body>  

                    <Modal.Footer>
                        <div className="d-flex gap-3">                         
                            <Button variant="contained" color="error" size="small" onClick={closeConfirmationBox}>ביטול</Button>  
                            <Button variant="contained" color="success" size="small" type="submit">אישור</Button>                         
                        </div>
                    </Modal.Footer> 
                </Form>        
            </Modal> 
        </div>
    );
};

export default TeacherIdentityDetails;
