import { useState,useEffect } from "react";
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

const SelectInstitute=(props)=>{
    const [ins,setIns]=useState({
        id:0,
        name:''
    });
    const [institutes,setInstitutes]=useState([]);

    useEffect(()=>{

        const fetchInstitutes = async () => {
          try {
            const response = await axios.get(`${baseUrl}/institutes_by_tch.php`,{
                params: {
                    tchId:props.tchId                                   
                }
            })
           
            if(Array.isArray(response.data) && response.data.length){
                setInstitutes(response.data)
                setIns(response.data[0].id,response.data[0].name)
                props.setInstitute(response.data[0].id,response.data[0].name)
                props.isEmpty(false)                                             
            }                       
            else{            
                setInstitutes([])
                props.isEmpty(true) 
            }             
          } 
          catch (error) {
            props.alert(error.message,'ERROR') ; 
          }
          
        }
  
        fetchInstitutes();
                                   
      },[props.refresh1,props.refresh2]);
//-------------------------------------------------------------------------------------------  

    return(

        <div>
            <select 
                class="form-select" 
                name="institutes"
                style={{minWidth:"100px"}} 
                value={ins.id} 
                onChange={(e)=>{                    
                    setIns(e.target.value,e.target.options[e.target.selectedIndex].text)
                    props.setInstitute(e.target.value,e.target.options[e.target.selectedIndex].text)                    
                }}
            >                                           
                {
                   institutes.map((c)=>(
                       <option value={c.id}>{c.name}</option>
                ))}                                                                                              
            </select> 
        </div>

    )
}

export default SelectInstitute;
