import React, { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import HomeCarousel from "../Components/carousel";
import SuggestedCourses from "../Components/suggested_courses";
import SuggestedTeachers from "../Components/suggested_teachers";
import FollowingTeachers from "../Components/following_teachers";
import { Alert } from "@mui/material";
import AlertTitle from '@mui/material/AlertTitle';
import '../Components/alert.css';

const Home = (props) => {
  const [screenHeight, setScreenHeight] = useState(0);
  const [alert, setAlert] = useState({
    msg: null,
    status: 'SUCCESS',
  });

  const carouselRef = useRef(null);

  useEffect(() => {
    setScreenHeight(window.innerHeight - 200);
  }, []);
//-------------------------------------------------------------------------------------------

  const scrollToBottomOfCarousel = () => {
    if (carouselRef.current) {
      window.scrollTo({
        top: carouselRef.current.offsetTop + carouselRef.current.offsetHeight,
        behavior: 'smooth', 
      });
    }
  };
//-------------------------------------------------------------------------------------------

  const set_alert = (msg, status) => {
    setAlert({
      msg: msg,
      status: status,
    });
    scrollToBottomOfCarousel(); 
  };
//-------------------------------------------------------------------------------------------

  return (

    <>  

      <div ref={carouselRef}>
        <HomeCarousel />
      </div>

      <div className="container-xl" style={{ minHeight: `${screenHeight}px` }}>
        {alert.msg != null ? (
          <div className="mt-4" dir="ltr">
            <Alert
              severity={alert.status.toLowerCase()} 
              variant="outlined"
              onClose={() => {
                setAlert({ msg: null, status: 'SUCCESS' }); 
              }}
            >
              <AlertTitle>
                <span className="bold-text">
                  {alert.status === 'SUCCESS' ? 'Success' : 'Error'}
                </span>
              </AlertTitle>
              <span className="bold-text">{alert.msg}</span>
            </Alert>
          </div>
        ) : null}
                                                    
        <div>
          <SuggestedCourses 
            user={props.user} 
            userId={props.userId}
            alert={set_alert} 
          />
        </div>
                                        
        <div>
          <SuggestedTeachers 
            user={props.user} 
            userId={props.userId} 
            alert={set_alert}
          /> 
        </div>

        {/*props.user === "STD" ? (
          <div className="mb-5">
            <FollowingTeachers 
              user={props.user} 
              userId={props.userId} 
              alert={set_alert}
            />
          </div>
        ) : (
          <div className="mb-5"></div>
        )*/}
        <div className="mb-5"></div>
      </div>
    </>
  );
};

export default Home;
