import { Navbar, Nav,NavDropdown } from 'react-bootstrap';
import { Outlet, Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import ShoppingCartBadge from '../Components/cart_badge';
import MessageBadge from '../Components/message_badge';
import HomeIcon from '@mui/icons-material/Home';
import '../Layouts/header.css'
import { FaSearch } from 'react-icons/fa';
import LogoutIcon from '@mui/icons-material/Logout';
import Help from '../Components/help';


const StdHeader = (props) => {
        
  return (
    <>
      <Navbar className='position-relative' collapseOnSelect expand='lg' bg='dark' variant='dark'>
          
          <Navbar.Toggle className='ms-3' aria-controls='responsive-navbar-nav'/>
             
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className="nav mt-4 mt-lg-2 mb-4 mb-lg-2 ms-4  align-items-start align-items-lg-center">
                <Avatar src={props.avatar} />
                <Link className='nav-item' to="/"><HomeIcon/></Link>
                <Link className='nav-item' to="/stdcourses">קורסים שלי</Link>
                <Link className='nav-item' to="/stdclassonline">כיתה מכוונת</Link>
                <Link className='nav-item' to="/stdprofile">פרופיל</Link>
                <Link className='nav-item' to="/contact">צור קשר</Link>
                <Link className='nav-item' to="/about">אודות</Link> 

                <NavDropdown 
                  title="עזרה" 
                  id="navbarScrollingDropdown" 
                >
                  <NavDropdown.Item 
                    style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                  >
                    <i className="fas fa-user-graduate"></i> 
                    <Help btn_txt="מדריך תלמיד" help="student_guide.pdf" />
                  </NavDropdown.Item>
                </NavDropdown>
                {/*<Link className='nav-item' to="/messages"><MessageBadge/></Link> */ }
                <Link className='nav-item me-lg-4 me-0' to="/search"><FaSearch size={20}/></Link>  
                <a className='nav-item ms-lg-4 ms-0' onClick={() => props.disconnect()}><LogoutIcon/><b> יציאה</b></a>                                                               
            </Nav>
          </Navbar.Collapse> 
          
          <div className='tch_brand-pos'>
            <Link className='nav-item me-4' to="/cart"><ShoppingCartBadge amount={props.amount}/></Link>
            <Navbar.Brand href="/" className="text-light ms-4">
              <b>DoroosOnline</b>
            </Navbar.Brand>
          </div>   

      </Navbar>

      <Outlet />

    </>
  )
};

export default StdHeader;