import React, {useState,useEffect} from "react";
import PageTitle from "../Components/page_title";
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import TeacherConBox from "../Components/tch_confirm_box";
import ReactLoading from 'react-loading';
import axios from 'axios';
import { Alert } from "@mui/material";
import AlertTitle from '@mui/material/AlertTitle';
import '../Components/alert.css';

const baseUrl = process.env.REACT_APP_BASE_URL; 

const ConfirmTeachers = (props) => {

  const [tchs,setTchs]=useState([]);
  const [screenHeight, setScreenHeight] = useState(0);
  const [fire,setFire]=useState(false);
  
  const [alert, setAlert] = useState({
    msg: null,
    status: 'SUCCESS',
  });

  useEffect(() => {
    setScreenHeight(window.innerHeight-200);
  }, []); 
//-------------------------------------------------------------------------------------------

  useEffect(()=>{ 
    
    const fetchTeachers = async () => {
      try {
          setFire(true); 
  
          let response;
          if (props.user === "ADMIN") { 
              response = await axios.get(`${baseUrl}/tchs_to_confirm.php`);
          } else {
              response = await axios.get(`${baseUrl}/tchs_to_confirm_by_sch.php`, {
                  params: { schId: props.id }
              }); 
          }
  
          if (response.data.status === 'success') {
              setTchs(Array.isArray(response.data.records) ? response.data.records : []);
          } else if (response.data.status === 'error') {
              throw new Error(response.data.message || 'An unknown error occurred');
          }
  
      } catch (error) {
          set_alert('Error fetching teachers: ' + error.message,'ERROR');
          setTchs([]); 
      } finally {
          setFire(false);
      }
    };
  
    fetchTeachers();
        
  },[]);
//-------------------------------------------------------------------------------------------

  const goToTop = () => {
    window.scrollTo({
        top:0,
        behavior: 'auto',
        behavior: 'smooth',  
    })
  }
//-------------------------------------------------------------------------------------------

  const set_alert = (msg,status) =>{
    setAlert({
      msg:msg,
      status:status,
    })
    goToTop()
  }
//-------------------------------------------------------------------------------------------

  const updateTchStatus = async (userId, comment, status) => {
    try {

        const endpoint = props.user === "ADMIN" ?  
            `${baseUrl}/update_tch_status.php` : 
            `${baseUrl}/update_tch_status_by_sch.php`;

        const params = props.user === "ADMIN" ? 
            { userId, comment, status } : 
            { tchId: userId, schId: props.id, comment, status };

        const response = await axios.get(endpoint, { params });

        if (response.data.status === 'success') {
          return response.data;        
        } else {
          throw new Error(response.data.message || 'An unknown error occurred');
        }

    } catch (error) {
        
      throw new Error(`Failed to update teacher status: ${error.message}`);
    }
}
//-------------------------------------------------------------------------------------------

   return (

    <div className="container mb-4" style={{ minHeight: `${screenHeight}px` }}>
                      
        <div className="mt-4 mb-4">
          <PageTitle text="אישור מורים"/>
        </div>
                
        <div className="d-flex flex-column gap-2 position-relative">

          {fire?
            <div className="position-absolute top-50 start-50 translate-middle-x mt-5" style={{zIndex: "1"}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
            :
            null
          }

          {
            alert.msg != null ? (
              <div className="mb-2" dir="ltr">
                <Alert
                  severity={alert.status.toLowerCase()} // Set severity based on the alert status ('success', 'error', etc.)
                  variant="outlined"
                  onClose={() => {
                    setAlert({ msg: null, status: 'SUCCESS' }); // Reset alert
                  }}
                >
                  <AlertTitle>
                    <span className="bold-text">
                      {alert.status === 'SUCCESS' ? 'Success' : 'Error'}
                    </span>
                  </AlertTitle>
                  <span className="bold-text">{alert.msg}</span>
                </Alert>
              </div>
            ) : null
          }

          {tchs.map((tch,index)=>(                                                                                                          
            <TeacherConBox 
              index={index+1}
              bgcolor={index%2===0?"rgb(240, 245, 240)":"rgb(243, 240, 245)"}                                                   
              userName={tch.f_name +" " + tch.l_name}
              update={tch.up_date}
              userId={tch.userId}  
              user={props.user}
              adminId={props.id}
              id={tch.id} 
              photo={tch.photo} 
              updateTchStatus={updateTchStatus}                                                                                                                                                   
              decreaseTchsNum={props.decreaseTchsNum}  
              alert={set_alert}                                                                                                                         
            />                       
          ))}
        </div>               
    </div>
  );
};

export default ConfirmTeachers;


