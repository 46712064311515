import React, { useState,useEffect } from "react";
import { Modal,Table} from 'react-bootstrap';
import Button from '@mui/material/Button';  
import { VscFiles } from "react-icons/vsc"
import ReactLoading from 'react-loading';
import axios from 'axios';
import '../Components/forms.css'

const baseUrl = process.env.REACT_APP_BASE_URL; 

const StdsInCourseReport=(props)=>{ 

    const [lines,setLines]=useState([]);   
    const [show,setShow]=useState(false);        
    const [fire,setFire]=useState(false);
    
    useEffect(() => {
      const fetchStdsInCourse = async () => {
        try {
          setFire(true); 
    
          const response = await axios.get(`${baseUrl}/stds_in_course.php`, {
            params: {
              code: props.code
            }
          });
    
          setLines(Array.isArray(response.data) && response.data.length ? response.data : []);
    
        } catch (error) {
          alert('Error fetching students in course:', error);
          
        } finally {
          setFire(false);
        }
      };
    
      fetchStdsInCourse();
    
      if (!props.link) {
        setShow(true); 
      }
    
    }, []); 
    
//-------------------------------------------------------------------------------------------

  const closeReport=()=>{    
    setShow(false)
    if(!props.link)
      props.resetMenu(0);
  }
//-------------------------------------------------------------------------------------------

  const OpenReport=()=>{
    setShow(true)
  }
//-------------------------------------------------------------------------------------------

  return (  
      <div> 
                   
        {props.link? 
          <button type="button" 
                  className="btn btn-outline-secondary btn-sm daily-prog-btn" 
                  onClick={OpenReport} 
                  style={{width:"100%"}}
          >
            <span>מי משתתף בקורס?</span>
          </button>
          :
          null
        }

        <Modal 
              show={show}  
              size="md"
              aria-labelledby="contained-modal-title-vcenter"             
        >  
          <Modal.Header style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}>
            <div className="d-flex gap-2 align-items-center">
              <div><VscFiles size={30} /></div>
              <div className="mt-2"><h5>דוח משתתפים בקורס</h5></div>
            </div>
            <button
                type="button"
                className="custom-modal-close"
                aria-label="Close"
                onClick={closeReport}
            >
                &times;  {/* Close "X" icon */}
            </button>
          </Modal.Header>
                             
          <Modal.Body>
            
            <div className="position-relative">

              {fire?
                <div className="position-absolute top-50 start-50 translate-middle-x" style={{zIndex: "1",top:"300",left:'300'}}>
                    <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
                </div>
                :
                null
              }               
                                                                    
              <div class="mt-1">                                        
                <Table hover >
                  <thead className=" text-center">
                    <tr>
                      <th>#</th>
                      <th>שם פרטי</th>
                      <th>שם משפחה</th>
                    </tr>
                  </thead>
                  <tbody className=" text-center">
                    {
                      lines.map((row,index)=>(
                        <tr>                          
                          <td>{index+1}</td>
                          <td>{row.std_fname}</td>                       
                          <td>{row.std_lname}</td>                                                                            
                        </tr>                          
                      ))
                    } 
                  </tbody>                                                                                                                                 
                </Table>
              </div>

            </div>
            
          </Modal.Body>
                     
          <Modal.Footer>                                      
            <Button className="me-2" variant="contained" color="error"   size="small" onClick={closeReport}>סגור</Button>
          </Modal.Footer>

        </Modal> 
      </div>        
    )  
  } 
  
export default StdsInCourseReport;  
