import React, { useEffect, useState } from 'react';
import AccessibleOutlinedIcon from '@mui/icons-material/AccessibleOutlined';
import "./accessibility_menu.css"; 

const AccessibilityMenu = () => {

  const [buttonPosition, setButtonPosition] = useState(180); 
  const [menuOpen, setMenuOpen] = useState(false); 
  const [scrollDirection, setScrollDirection] = useState(null); 
  const [isHighContrast, setIsHighContrast] = useState(false);
  const [fontSize, setFontSize] = useState(16); 

  useEffect(() => {
    let scrollTimeout;
    let lastScrollY = window.scrollY;
    

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      
      if (currentScrollY > lastScrollY) {
        setScrollDirection('down');
        setButtonPosition(80); 
      } else if (currentScrollY < lastScrollY) {
        setScrollDirection('up');
        setButtonPosition(280); 
      }

      lastScrollY = currentScrollY;

      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        setButtonPosition(180); 
      }, 300); 
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const increaseFontSize = () => {
    setFontSize((prevSize) => prevSize + 2);
    document.body.style.fontSize = `${fontSize + 2}px`;
  };

  const decreaseFontSize = () => {
    setFontSize((prevSize) => (prevSize > 10 ? prevSize - 2 : prevSize));
    document.body.style.fontSize = `${fontSize - 2}px`;
  };

 const toggleHighContrast = () => {
    setIsHighContrast(!isHighContrast);
    if (!isHighContrast) {
      document.body.classList.add("high-contrast");
    } else {
      document.body.classList.remove("high-contrast");
    }
  };

  return (
    <div
      className="accessibility-menu"
      style={{
        position: 'fixed',
        bottom: `${buttonPosition}px`, 
        left: '0',
        transition: 'bottom 0.5s ease', 
        zIndex: 10000, 
      }}
    >
      <button className="accessibility-button" onClick={toggleMenu}>
        <AccessibleOutlinedIcon fontSize="large" />
      </button>

      {menuOpen && (
        <div
          className="accessibility-options"
          style={{
            position: 'absolute',
            bottom: '65px', 
            left: '0',
            zIndex: 9999, 
          }}
        >
          <button onClick={increaseFontSize}>הגדל טקסט</button>
          <button onClick={decreaseFontSize}>הקטן טקסט</button>
          <button onClick={toggleHighContrast}>
            {isHighContrast ? "כבה ניגודיות גבוהה" : "הפעל ניגודיות גבוהה"}
          </button>
        </div>
      )}
    </div>
  );
};

export default AccessibilityMenu;

