import React, { useState,useEffect } from "react";
import axios from 'axios';
import {Table} from 'react-bootstrap'; 
import '../Components/course_schedule.css';

const baseUrl = process.env.REACT_APP_BASE_URL;

const CourseSchedule=(props)=>{ 
    const [lines,SetLines]=useState([]);

    useEffect(()=>{

      const fetchScheduleLines = async () => {
        try{
          const response = await axios.get(`${baseUrl}/schedule.php?code=${props.code}`)
          
          if(Array.isArray(response.data) && response.data.length )           
            SetLines(response.data) 
          else
            SetLines([]);
          
        } 
        catch (error) {

          props.alert(error.message,'ERROR') ;
         
        } 
      }
      fetchScheduleLines();          
    },[props.refresh]);
//-------------------------------------------------------------------------------------------

    return ( 
      <>
        {lines.length>0?
          <div className="mt-2 mb-5"> 
            <div className="d-flex justify-content-start">
              <p className="schedule" >לומדים בימים:</p>
            </div>

            <div className="sdl-table shadow-sm">        
                              
              <Table hover>
                <thead className=" text-center">
                  <tr>
                    <th>#</th>
                    <th>יום</th>
                    <th>שעה</th>
                    <th>מספר שיעורים</th>
                  </tr>
                </thead>
                <tbody className=" text-center">
                {
                  lines.map((line,index)=>(
                    <tr>
                      <td>{index+1}</td>
                      <td>{line.day}</td>
                      <td>{line.time}</td>
                      <td>{line.num}</td>                          
                    </tr>                          
                  )) 
                }                           
                </tbody>
              </Table>
            </div> 
          </div>
          :null} 
      </>      
    )  
  }  
export default CourseSchedule;  
