import React, { useState,useEffect } from "react";
import {Table} from 'react-bootstrap'; 
import { FcApproval } from "react-icons/fc";
import { MdCancel } from "react-icons/md";
import { FaRegEyeSlash } from "react-icons/fa";
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

const DocsForProfile=(props)=>{ 

    const [lines,setLines]=useState([]);   
   
    useEffect(()=>{

      const fetchDocLines = async () => {
        try{
            
            if(props.user==='ADMIN'){
              var response = await axios.get(`${baseUrl}/docs_list_for_profile.php`,{
                params: {
                    userId:props.userId                                                                   
                }
              })
            }
            else{  
              var response = await axios.get(`${baseUrl}/docs_list_for_profile_by_sch.php`,{
                params: {
                    userId:props.userId,
                    adminId:props.adminId                                                                   
                }
              })
            }
                             
            if(Array.isArray(response.data) && response.data.length ){          
              setLines(response.data)                                  
            }
            else{                       
              setLines([]);                         
            }                              
        } 
        catch (error) {

          props.alert(`Failed to retrieve documents list: ${error.message}`, 'ERROR');
        }        
      }

      fetchDocLines();
      
    },[]);
//-------------------------------------------------------------------------------------------
   
  return (                                                                                                                                                                                            
      <div class="mt-4">
        {lines.length>0?                                       
          <Table hover >
            <thead className=" text-center">
              <tr>
                <th>#</th>
                <th>מסמך</th>
                <th>סטטוס</th>
              </tr>
            </thead>
            <tbody className=" text-center">
              { 
                lines.map((row,index)=>(
                  <tr>                          
                    <td>{index+1}</td>
                    <td>{row.s_doc_name!=null?row.s_doc_name:row.t_doc_name}</td> 
                    {row.s_status!=null?                                        
                      <td>{row.s_status==='NO_RESPONSE'?<FaRegEyeSlash color="red" size={25}/>:row.s_status==='APPROVED'?<FcApproval size={25}/>:<MdCancel color="red" size={25}/>}</td>
                      : 
                      <td>{row.t_status==='NO_RESPONSE'?<FaRegEyeSlash color="red" size={25}/>:row.t_status==='APPROVED'?<FcApproval size={25}/>:<MdCancel color="red" size={25}/>}</td> 
                    }                                         
                  </tr>                          
                ))
              } 
            </tbody>                                                                                                                                 
          </Table>
          :null
        }
      </div>       
    )
  };   

export default DocsForProfile;  
