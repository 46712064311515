import React, { useState } from "react";
import './popup_modals.css';
import axios from 'axios';
import SelectInstitute from "./select_institute";

const baseUrl = process.env.REACT_APP_BASE_URL;

const AddInstitute=(props)=>{ 
    
    const [refresh,setRefresh]=useState(false);    
    const [empty,setEmpty]=useState(false);                                                                 
    const [instituteDtls, setInstituteDtls] = useState({        
        id:0,
        name:''    
    });
   
//-------------------------------------------------------------------------------------------  
    
    const isEmpty = (flag) => {
                
        setEmpty(flag)

    } 
//-------------------------------------------------------------------------------------------      
    const getInstitute=(id,name)=>{

        setInstituteDtls({['id']:id,['name']:name} )
        
    }
//-------------------------------------------------------------------------------------------

    const addNewLine = async () =>{          
                               
        try{
            const response = await axios.get(`${baseUrl}/pair_tch_institute.php`,{
                params: {
                    tchId:props.tchId,
                    ins:instituteDtls.id
                }
            })
           
            if(response.data.id>0){
                const institute={id:response.data.id,name:instituteDtls.name,comment:'',status:'NO_RESPONSE'};
                props.add(institute);
                setRefresh(prev=>!prev)                                                                   
            }
            else{
                props.alert("שיוך מרכז לימוד למורה נכשל!!",'ERROR')
            }
        }
        catch(error){
            props.alert(error.message,'ERROR')
        }                           
    }
//-------------------------------------------------------------------------------------------
        
    return (  
                
        <div className="d-flex flex-wrap gap-4 align-items-center"> 
                    
            <div className="label-100-wrap">
                <label class="form-label label-100" for="institute">מרכז לימוד</label>              
                <div id="institute">
                    <SelectInstitute setInstitute={getInstitute} tchId={props.tchId} refresh1={props.refresh} refresh2={refresh} isEmpty={isEmpty} />
                </div>
            </div>
            
            <div>
                {(props.count<5 && !empty)?            
                    <button class="btn btn-primary btn-sm" style={{borderRadius:"20px",width:"70px"}} onClick={addNewLine}>הוסף</button>
                    :
                    <button class="btn btn-primary btn-sm" style={{borderRadius:"20px",width:"70px"}} disabled>הוסף</button>
                }
            </div>                                                                              
            
        </div> 
                          
    )  
  } 
  
export default AddInstitute;  
