import React, {useState,useEffect} from "react";
import ContactForm from '../Components/contact_form '
import PageTitle from '../Components/page_title';
import '../Pages/contact_form.css'
import { Alert } from "@mui/material";
import AlertTitle from '@mui/material/AlertTitle';
import '../Components/alert.css';

const Contact = () => {
  const [screenHeight, setScreenHeight] = useState(0);

  const [alert, setAlert] = useState({
    msg: null,
    status: 'SUCCESS',
  });

  useEffect(() => {
    setScreenHeight(window.innerHeight-200);
  }, []); 
//-------------------------------------------------------------------------------------------

  const goToTop = () => {
    window.scrollTo({
        top:0,
        behavior: 'auto',
        behavior: 'smooth',  
    })
  }
//-------------------------------------------------------------------------------------------

  const set_alert = (msg,status) =>{
    setAlert({
      msg:msg,
      status:status,
    })
    goToTop()
  }
//-------------------------------------------------------------------------------------------

  return(
     
      <div className='d-flex flex-column align-items-center mb-5' style={{ minHeight: `${screenHeight}px` }}>                  
        <div className='msg-wrap'>

            <div className="mt-4 mb-4">
              <PageTitle text="צור קשר"/>
            </div >

            {
              alert.msg != null ? (
                <div className="mb-2" dir="ltr">
                  <Alert
                    severity={alert.status.toLowerCase()} // Set severity based on the alert status ('success', 'error', etc.)
                    variant="outlined"
                    onClose={() => {
                      setAlert({ msg: null, status: 'SUCCESS' }); // Reset alert
                    }}
                  >
                    <AlertTitle>
                      <span className="bold-text">
                        {alert.status === 'SUCCESS' ? 'Success' : 'Error'}
                      </span>
                    </AlertTitle>
                    <span className="bold-text">{alert.msg}</span>
                  </Alert>
                </div>
              ) : null
            }
            
            <div  className="msg-form">
              <ContactForm alert={set_alert}/>
            </div>
        </div>          
      </div>             
  )};
  
  export default Contact;
