import  React, {useState,useEffect} from "react";
import PageTitle from "../Components/page_title";
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import DocumentConBox from "../Components/doc_confirm_box";
import ReactLoading from 'react-loading';
import axios from 'axios';
import { Alert } from "@mui/material";
import AlertTitle from '@mui/material/AlertTitle';
import '../Components/alert.css';

const baseUrl = process.env.REACT_APP_BASE_URL; 

const ConfirmDocuments = (props) => {

  const [docs,setDocs]=useState([]);
  const [screenHeight, setScreenHeight] = useState(0);
  const [fire,setFire]=useState(false);

  const [alert, setAlert] = useState({
    msg: null,
    status: 'SUCCESS',
  });

  const goToTop = () => {
    window.scrollTo({
        top:0,
        behavior: 'auto',
        behavior: 'smooth',  
    })
  }
//-------------------------------------------------------------------------------------------

  const set_alert = (msg,status) =>{
    setAlert({
      msg:msg,
      status:status,
    })
    goToTop()
  }
//-------------------------------------------------------------------------------------------

  useEffect(() => {
    setScreenHeight(window.innerHeight-200);
  }, []);
//-------------------------------------------------------------------------------------------
  
  useEffect(()=>{
    
    const fetchDocuments = async () => {
      try {
          setFire(true);
  
          let response;
          if (props.user === "ADMIN") {
              response = await axios.get(`${baseUrl}/docs_to_confirm.php`);
          } else {
              response = await axios.get(`${baseUrl}/docs_to_confirm_by_sch.php`, {
                  params: { schId: props.id }
              });
          }
          
          if (response.data.status === 'error') {
              throw new Error(response.data.message || 'An error occurred while fetching documents');
          }
  
          if (response.data.status === 'success') {
              setDocs(response.data.records || []);
          } else {
              throw new Error('Unexpected response status');
          }
      } catch (error) {
          set_alert('Error: ' + error.message,'ERROR');
          setDocs([]);
      } finally {
          setFire(false);
      }
  };
  
  
    fetchDocuments();
        
  },[]);
//-------------------------------------------------------------------------------------------

  const updateDocStatus = async (userId, docId, user, jsonData, status) => {
    try {
      
      let response;
      
      if(props.user==="ADMIN"){

        response = await axios.post(`${baseUrl}/update_doc_status.php`, jsonData, {
          params: {
              userId: userId,
              docId: docId,
              user: user,
              status: status,
          },
          headers: {
              'Content-Type': 'application/json',
          },
        });
      
      } else {
        response=await axios.get(`${baseUrl}/update_doc_status_by_sch.php`,jsonData,{
          params: {
              docId:docId,
              user:user,
              status:status                                         
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }

      if (!response.data || typeof response.data !== 'object') {
          throw new Error("Invalid response from server");
      }

      if (response.data.status === 'error') {
          throw new Error(response.data.message);
      }

    } catch (error) {
       
        throw error; 
    }
  }
//-------------------------------------------------------------------------------------------

   return (

    <div className="container mb-4" style={{ minHeight: `${screenHeight}px` }}>
                      
        <div className="mt-4 mb-4">
          <PageTitle text="אישור מסמכים"/>
        </div>
        
        <div className="d-flex flex-column gap-2 position-relative">

          {fire?
            <div className="position-absolute top-50 start-50 translate-middle-x mt-5" style={{zIndex: "1"}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
            :
            null
          }

          {
            alert.msg != null ? (
              <div className="mb-2" dir="ltr">
                <Alert
                  severity={alert.status.toLowerCase()} // Set severity based on the alert status ('success', 'error', etc.)
                  variant="outlined"
                  onClose={() => {
                    setAlert({ msg: null, status: 'SUCCESS' }); // Reset alert
                  }}
                >
                  <AlertTitle>
                    <span className="bold-text">
                      {alert.status === 'SUCCESS' ? 'Success' : 'Error'}
                    </span>
                  </AlertTitle>
                  <span className="bold-text">{alert.msg}</span>
                </Alert>
              </div>
            ) : null
          }

          {docs.map((doc,index)=>(                                                                                                           
            <DocumentConBox 
              index={index+1}
              bgcolor={index%2===0?"rgb(240, 245, 240)":"rgb(243, 240, 245)"}                                                   
              userName={doc.f_name +" " + doc.l_name}
              userId={doc.userId}
              user={doc.t_docId!==null?"TCH":"SCH"}
              date={doc.t_docId!==null?doc.t_date:doc.s_date}
              file={doc.t_docId!==null?doc.t_file:doc.s_file}                                                               
              docId ={doc.t_docId!==null?doc.t_docId:doc.s_docId}                                      
              status={doc.t_docId!==null?doc.t_status:doc.s_status} 
              docType={doc.t_docId!==null?doc.t_docName:doc.s_docName}
              recipient={doc.t_docId!==null?doc.t_recipient:doc.s_recipient}
              decreaseDocsNum={props.decreaseDocsNum} 
              updateDocStatus={updateDocStatus} 
              alert={set_alert}                                                                                                                         
            />                        
          ))}
        </div>               
    </div>
  );
};

export default ConfirmDocuments; 


