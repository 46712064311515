import { useState,useEffect } from "react";
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

const SelectSubject=(props)=>{
   
    const [subjects,setSubjects]=useState([])
    const [sub,setSub]=useState(-1)

    useEffect(()=>{
        
        const fetchSubjects = async () => {
            try {
                const response = await axios.get(`${baseUrl}/subjects.php`)
            
                if(response.data.length>0){
                    setSubjects(response.data)
                    if(!props.editing) 
                        props.setSubject(response.data[0].id)
                }                                                                                                             
                else            
                    setSubjects([])              
                } 
            catch (error) {
                props.alert(error.message,'ERROR') ;
            }  
        }

        fetchSubjects();
                            
    },[]);
//-------------------------------------------------------------------------------------------
    
    useEffect(()=>{
        
        setSub(props.subId)
                
    },[props.subId])
//-------------------------------------------------------------------------------------------

    return(

        <div>
            <select 
                class="form-select" 
                name="subjects" 
                value={sub} 
                onChange={(e)=>{                    
                    setSub(e.target.value)
                    props.setSubject(e.target.value)                    
                }}
            >                                           
                {
                   subjects.map((s)=>(
                       <option value={s.id}>{s.name}</option>
                ))}                                                                                              
            </select> 
        </div>

    )
}

export default SelectSubject;
