import React, { useState,useEffect} from "react";
import {Modal} from 'react-bootstrap'; 
import Button from '@mui/material/Button'; 
import "react-datepicker/dist/react-datepicker.css";
import './popup_modals.css';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { replayToMsg } from "./db";
import * as Yup from 'yup';
import '../Components/forms.css'; 
import ReactLoading from 'react-loading';
import useEmailSender from "./email_sender";


const emailSchema = Yup.object().shape({

    subject: Yup.string()
        .min(5, 'Subject must be at least 5 characters')
        .required('Subject is required'),

    content: Yup.string()
        .min(5, 'Content must be at least 5 characters')
        .required('Content is required'),
    
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
});

const htmlWrap=(msg,repaly)=>{
    return(
    `<!DOCTYPE html>
    <html>
        <head>
            <title>Example HTMLWrap Email</title>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <style type="text/css">
                * {
                    box-sizing: border-box;
                }
                body {
                    font-family: Arial, sans-serif;
                    background-color: #f5f5f5;
                    margin: 0;
                    padding: 0;
                }
                .container {
                    max-width: 600px;
                    margin: 0 auto;
                    padding: 20px;
                    background-color: #ffffff;
                }
                h1 {
                    font-size: 24px;
                    color: #000000;
                    margin-top: 0;
                    margin-bottom: 20px;
                }
                p {
                    font-size: 16px;
                    line-height: 1.5;
                    color: #333333;
                    margin-top: 0;
                    margin-bottom: 20px;
                }
            </style>
        </head>
        <body dir="rtl">
            <div class="container">
                <h1>הודעה מ DoroosOnline.com</h1>
                <div style="color:gray">${msg}</div>
                <br>
                <div style="color:blue">${repaly}</div>
            </div>
        </body>
    </html>`)
}
//------------------------------------------------------------------------------------------- 

const ReplayToMsg=(props)=>{ 

    const [fire,setFire]=useState(false);
    const [errors, setErrors] = useState({});
    const [show,setShow]=useState(false);
    
    const [replayMsgDtls, setReplayMsgDtls] = useState({
        email:props.email,
        subject:props.subject,
        content:'',           
    });

    const { sendEmail } = useEmailSender();
    
//------------------------------------------------------------------------------------------- 

    useEffect(()=>{        
        if(!props.link)
            setShow(true);
    },[]);
//------------------------------------------------------------------------------------------- 

    const alert=(msg,status)=>{
        closeReplayToMsg()
        props.alert(msg,status)
    }
//------------------------------------------------------------------------------------------- 
        
    const handleChange = event => {
        var { name, value } = event.target;
        
        setReplayMsgDtls(prevState => ({
            ...prevState,
            [name]: value
        }));   
    };
//------------------------------------------------------------------------------------------- 
            
    const handleSubmit = async (event) => {
                                
        event.preventDefault(); 
                                                  
        try {

            await emailSchema.validate(replayMsgDtls, { abortEarly: false });  
            
            setFire(true)

            const currentDate = new Date();
            const replayDate =`<div style="font-size:x-small">${currentDate.toISOString().slice(0, 19).replace('T', ' ')}</div>`;

            const msgDate=`<div style="font-size:x-small">${props.date}</div>`;
                        
            let msg;
            
            if (props.status !== 'Sent') {
                msg = props.msg.replace("<split>", "<br><br>") + msgDate;
            } else {
                msg = props.msg.replace("<split>", "<br><br>");
            }

            const replay=replayMsgDtls.content.replace(/\n/g,"<br>")+replayDate;

            sendEmail(replayMsgDtls.email,replayMsgDtls.subject,htmlWrap(msg,replay))
                .then(async (data) => {

                    const msg1=`${msg}<split>${replay}`;                     
                    const code=await replayToMsg(props.msgId,msg1)
                      
                    if( code > 0 ){
                        alert("דואר אלקטרוני נשלח ליעד בהצלחה!!",'SUCCESS') 
                        props.updateMessage(props.index,msg1)                       
                    }                  
                    else 
                        alert("שמירת ההודעה לא הושלמה בהצלחה!!",'ERROR')

                })
                .then(()=>{
                    setFire(false)
                    setReplayMsgDtls({
                        email:'',
                        subject:'',
                        content:'',                       
                    })
                                    
                    setErrors({}) 
                })
                .catch(error => {                    
                    alert("שליחת האימייל נכשלה!!",'ERROR')
                    setFire(false)
                    setReplayMsgDtls({
                        email:'',
                        subject:'',
                        content:'',                        
                    })                                    
                    setErrors({})
                });                                                                       
                                                               
        }catch (err) {
      
            setErrors(err.inner.reduce((acc, { path, message }) => {
              acc[path] = message;
              return acc;
            }, {}));
        }                
    }       
//------------------------------------------------------------------------------------------- 

    const openReplayToMsg = () => { 
        setShow(true);
    }
//------------------------------------------------------------------------------------------- 

    const closeReplayToMsg = () => { 

        setShow(false);

        setErrors({})

        if(!props.link)
            props.resetMenu(0);
    }
//------------------------------------------------------------------------------------------- 

    return (  
        <div>        
            {props.link?
                <>  
                    <Button 
                        variant="contained" 
                        color="success" 
                        size="small" 
                        onClick={openReplayToMsg}
                        disabled={props.status==='Sent'}
                    >
                        כתוב תגובה
                    </Button>                                                                                   
                </>
                :null
            }           
                        
            <Modal 
              show={show}  
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"            
             >  

                <Modal.Header style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}>
                    <div className="d-flex gap-2 align-items-center">
                        <div><FaChalkboardTeacher size={30} /></div>
                        <div className="mt-1"><h5>כתוב תגובה</h5></div>
                    </div> 
                    <button
                        type="button"
                        className="custom-modal-close"
                        aria-label="Close"
                        onClick={closeReplayToMsg}
                    >
                        &times;  {/* Close "X" icon */}
                    </button>                                             
                </Modal.Header>  
                
                <form onSubmit={handleSubmit}>
                    <Modal.Body> 
                    <div className="container">
                        <div class="position-relative d-flex flex-column gap-4 mt-4">

                            {fire?
                                <div className="position-absolute top-50 start-50 translate-middle-x mt-5" style={{zIndex: "1"}}>
                                    <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
                                </div>
                                :
                                null
                            }
                                                                                                                        
                            <div className="label-100-wrap">                
                                <label className="form-label label-100" for="subject">שלח אל</label>
                                <div>
                                    <input  
                                        type="text" 
                                        className={`form-control ${errors.email? 'invalid' : ''}`} 
                                        name="email" 
                                        value={replayMsgDtls.email} 
                                        onChange={handleChange}
                                        readOnly                                         
                                    />
                                    {errors.email && <p className="error">{errors.email}</p>}
                                </div>   
                            </div>

                            <div className="label-100-wrap mt-3">                
                                <label className="form-label label-100" for="subject">נושא</label>
                                <div>
                                    <input  
                                        type="text" 
                                        className={`form-control ${errors.subject? 'invalid' : ''}`} 
                                        name="subject" 
                                        value={replayMsgDtls.subject} 
                                        onChange={handleChange} 
                                        readOnly                                       
                                    />
                                    {errors.subject && <p className="error">{errors.subject}</p>}
                                </div>   
                            </div>

                            <div  className="label-100-wrap mt-3">                
                                <label className="form-label label-100" for="content">הודעה</label>
                                <div dir="rtl">
                                    <textarea 
                                        className={`form-control ${errors.content? 'invalid' : ''}`} 
                                        name="content"   
                                        rows="4" 
                                        value={replayMsgDtls.content} 
                                        onChange={handleChange}                                       
                                    />
                                    {errors.content && <p className="error">{errors.content}</p>}                           
                                </div>   
                            </div> 
                        </div>                                                
                    </div>
                    </Modal.Body>  

                    <Modal.Footer>
                        <div className="d-flex gap-3 me-3 mb-2">                         
                            <Button variant="contained"  color="error"  size="small" onClick={closeReplayToMsg}>ביטול</Button>  
                            <Button type="submit" variant="contained" color="success" size="small" >שלח תגובה</Button>                         
                        </div>
                    </Modal.Footer> 
                </form>        
            </Modal> 
        </div>        
    )  
  } 
  
export default ReplayToMsg;
