import BasicRating  from "./rating";
import '../Components/teacher_card.scss'
import noPhoto from '../Assets/Images/img_avatar1.png'
import ShareButton from "./share_buttons";
import axios from 'axios'; 

const baseUrl = process.env.REACT_APP_BASE_URL;

const TeacherCard = (props) =>{ 

  
  const shareText = "הצטרפו לקורסים החדשים של *" + `${props.teacher}` + "* ב-DoroosOnline " + "🎓 ופתחו דלת לעולם של ידע 🌟! לפרטים נוספים ולהרשמה, לחצו על הקישור.";
  const courseLink = `https://www.doroosonline.co.il/search?tchId=${props.tchId}`;
  const followMe= async ()=>{
    try {
          const response = await axios.get(`${baseUrl}/follow_tchr.php`,{
            params: {                        
              tcId:props.tchId,
              stdId:props.stdId                                            
            }
          })

          if(response.data.id>0){
            alert("הצלחת לעקוב אחרי מורה זה בהצלחה!!")
            props.updateFollowingStatus(props.index,'1')
          }
          else
            alert ("מצטערים, ארעה שגיאה בעקיבת מורה זה. אנא נסה שוב מאוחר יותר!!")

      
    } 
    catch (error) {

      alert(error) ;
      
    }
  }
//-------------------------------------------------------------------------------------------

  const unfollowMe= async ()=>{
    try {
          const response = await axios.get(`${baseUrl}/unfollow_tchr.php`,{ 
            params: {                        
              tcId:props.tchId,
              stdId:props.stdId                                            
            }
          })

          if(response.data.id>0){
            alert("הסרת עקיבה אחרי מורה זה בהצלחה!!")
            props.updateFollowingStatus(props.index,'0')
          }
          else
            alert ("מצטערים, ארעה שגיאה בהסרת עקיבה אחרי מורה זה. אנא נסה שוב מאוחר יותר!!")

      
    } 
    catch (error) {

      alert(error) ;
      
    }
  }
//-------------------------------------------------------------------------------------------

  return (  
    <div className="tch-card-container" style={{background:props.bgcolor}}>
      <div className="card-box">
            
        <div className="tch-photo-container">
            <div className="circle-frame">
                <img 
                    src={props.photo !== null ? props.photo : noPhoto} 
                    alt="Teacher"
                    className="tch-photo" 
                />
            </div>
        </div>

        <div className="subject">{props.subject}</div>

        <div className="rating-container">
          <div className="teacher-rating">
            <p className="teacher-name">{props.teacher}</p>          
              <span dir="ltr">
                <BasicRating 
                  value={props.rating} 
                  ratings={props.ratings} 
                  readOnly={true} 
                  status={props.status}
                />
              </span>           
          </div>
        </div>

        {/*props.user==="STD"?  
        
          <>
            {props.following==='1'?
              
              <button 
                type="button" 
                className="follow-button" 
                onClick={unfollowMe}
              >
                הפסק לעקוב
              </button>               
              :              
              <button 
                type="button" 
                className="follow-button"  
                onClick={followMe}
              >
                עקוב אחרי
              </button> 
              
            }
          </>                  
          :
          null        
        */}        
      </div>

      <div className="position-absolute" style={{ top : 3, left: 3 }}>
        <ShareButton courseLink={courseLink} shareText={shareText}/>
      </div>

    </div>                                                                                                                                                                                     
  );
};
export default TeacherCard;



