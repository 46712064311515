
import React, {useState,useEffect} from "react";
import axios from 'axios';
import {Modal} from 'react-bootstrap'; 
import Button from '@mui/material/Button';
import CatBtn from "../Components/cat_btn";
import CourseIcon from "./course_icon";
import { BsImages  } from 'react-icons/bs';
import Pagination from '@mui/material/Pagination';
import '../Components/forms.css'

const baseUrl = process.env.REACT_APP_BASE_URL;

const SelectIcon=(props)=>{ 
    
  const [cats,setCats]=useState([]);        
  const [show,setShow]=useState(false);
  const [active,setActive]=useState(0);
  const [checked,setChecked]=useState(0);
  const [page, setPage] = useState(1);
  const [icons,setIcons]=useState([]);
  
  const win=12;
  
  useEffect(()=>{

    const fetchSubjects = async () => {
      try {
        const response = await axios.get(`${baseUrl}/subjects.php`)
        
        if(response.data.length>0)
          setCats(response.data)
        else
          setCats([]);
        
      } 
      catch (error) {
        alert(error.message,'ERROR') ; 
      }        
    }

    fetchSubjects();
    
    if(!props.link)
        setShow(true);
        
  },[]);
//-------------------------------------------------------------------------------------------

  useEffect(()=>{

    const fetchPhotos = async () => {
      try {
        const url=active===0?`${baseUrl}/photos.php`:`${baseUrl}/photos.php?sub_id=${active}`;
        const response = await axios.get(url);
        if(response.data && Array.isArray(response.data) && response.data.length > 0)
          setIcons(response.data);
        else
          setIcons([]);
      } 
      catch (error) {
        alert(error.message,'ERROR') ; 
      }        
    }

    fetchPhotos();
                      
  },[active]);
//-------------------------------------------------------------------------------------------

  function openIconModal(){ 
    setShow(true)
  }
//-------------------------------------------------------------------------------------------

  function closeIconModal(){ 
    setShow(false);
    if(!props.link)
      props.resetMenu(0);
  }
//-------------------------------------------------------------------------------------------    
    
  const alert=(msg,status)=>{
    closeIconModal()
    props.alert(msg,status)
  }
//------------------------------------------------------------------------------------------- 

  const getValueFromCat=(value)=>{
    setActive(value);     
    setPage(1);
  }
//-------------------------------------------------------------------------------------------
  const getValueFromIcon=(value)=>{
    setChecked(value);
  }
//-------------------------------------------------------------------------------------------
  const handleChange = (_,page)=> {
    
    if (page !== null) 
      setPage(page);
    
  }
//-------------------------------------------------------------------------------------------
   
  const handleIconChange= async () => {
    
    try {
      const response = await axios.get(`${baseUrl}/update_icon.php`,{
        params: {                        
          code:props.code,
          icon_id:checked                                                           
        }
      })
      
      if( response.data.icon_id > 0 ){         
        props.changeIcon(response.data.file)
        closeIconModal()
      }
      else
        alert("עדכון התמונה נכשל!!",'ERROR')
    } 
    catch (error) {
      alert(error.message,'ERROR') ;
    }      
  }
//-------------------------------------------------------------------------------------------

  return (  
    <div>        
      {props.link?<a href="#"  onClick={openIconModal}>בחירת תמונה</a>:null}
      <Modal 
          show={show}   
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"            
      >  
          <Modal.Header style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}> 
            <div className="d-flex gap-2 align-items-center">
              <div><BsImages size={30} /></div>
              <div className="mt-1"><h5>בחירת תמונה</h5></div>
            </div>
            <button
                type="button"
                className="custom-modal-close"
                aria-label="Close"
                onClick={closeIconModal}
            >
                &times;  {/* Close "X" icon */}
            </button>             
          </Modal.Header>

          <Modal.Body>
            <div className="container">  
              <div className="d-flex flex-wrap gap-2"> 
                <CatBtn value={0}  active={active}  getValueFromCat={getValueFromCat} text="הכל"/>  
                {
                  cats.map((c)=>(
                    <CatBtn value={c.id}  active={active}  getValueFromCat={getValueFromCat} text={c.name}/>
                ))} 
              </div>

              <div className="d-flex flex-wrap gap-2 mt-4">
                {
                  icons.slice((page-1)*win,(page-1)*win+win).map((icon)=>(                  
                    <CourseIcon value={icon.id} checked={checked} icon={icon.file} getValueFromIcon={getValueFromIcon}/>
                ))}                               
              </div>

              <div  class="d-flex justify-content-end mt-4" dir="ltr">                                 
                <Pagination count={Math.ceil(icons.length/win)} variant="outlined" shape="rounded" color="primary" page={page} onChange={handleChange} />         
              </div>
              
            </div>  
          </Modal.Body>

          <Modal.Footer>  
                  <div className="d-flex gap-2 me-3">
                      <Button variant="contained" color="error"   size="small" onClick={closeIconModal}>ביטול</Button>  
                      <Button variant="contained" color="success" size="small" onClick={handleIconChange} >שמירה</Button>                        
                  </div>  
          </Modal.Footer>

      </Modal> 
    </div>        
  )  
} 
export default SelectIcon;  
