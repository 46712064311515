import React from "react";
import { storage } from "./firebase";
import { ref, getDownloadURL } from "firebase/storage";

const DisplaySyllabus = (props) => {

  const openSyllabusBox = () => {
    if (props.syllabus !== "") {
      const fileRef = ref(storage, `syllabuses/${props.syllabus}`);
      getDownloadURL(fileRef)
        .then((url) => {
          window.open(url, '_blank'); // Open in a new tab
        })
        .catch((error) => {
          alert("Error loading PDF: " + error.message);
        });
    }
  };

  return (
    <div>
      <button 
        type="button" 
        className={`btn btn-link ${props.className}`}
        onClick={openSyllabusBox} 
        disabled={props.syllabus === ""}
        style={{ 
          outline: 'none', 
          boxShadow: 'none',
          border: 'none', 
        }}
      >
        {props.btn_txt}
      </button>
    </div>
  );
};

export default DisplaySyllabus;
