import React,{ useState,useEffect } from "react";
import {useLocation} from 'react-router-dom';
import AddMeetingLine from "../Components/add_meeting_line";
import PageTitle from "../Components/page_title";
import PaymentBox from "../Components/payment_box";
import Bill from "../Components/bill";
import axios from 'axios';
import StdMeetingReqBox from "../Components/std_meeting_req_box";
import { addMinutes } from "../Components/time";
import { sendReceipt,getStudentCredit } from "../Components/db";
import ReactLoading from 'react-loading';
import { Box } from '@mui/material'; 
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { Alert } from "@mui/material";
import AlertTitle from '@mui/material/AlertTitle';
import '../Components/alert.css';
 
const baseUrl = process.env.REACT_APP_BASE_URL; 

const MeetingsCoordination = (props) => { 

  const [lines,setLines]=useState([]);       
  const [refresh,setRefresh]=useState(false);
  const [lessonsArr,setLessonsArr]=useState([]);
  const [total,setTotal]=useState(0);
  const [credit,setCredit]=useState(0);
  const location = useLocation();
  const [screenHeight, setScreenHeight] = useState(0);
  const [flag,setFlag]=useState(false);
  const [fire,setFire]=useState(false); 
  const [showOverlay, setShowOverlay] = useState(false);
  
  const [alert, setAlert] = useState({
    msg: null,
    status: 'SUCCESS',
  });

  const turnFireOn=()=> setFire(true);
  const turnFireOff=()=>setFire(false);

  useEffect(() => {

    const fetchCredit = async () => {
        try {
            setFire(true);
            const stdCredit = await getStudentCredit(location.state.stdId);
            setCredit(stdCredit);
        } catch (error) {
            set_alert(error.message,'ERROR');
        } finally {
            setFire(false);
        }
    };

    fetchCredit(); 
    setScreenHeight(window.innerHeight - 200);

  }, []); 
//-------------------------------------------------------------------------------------------
    
  useEffect(()=>{

    const fetchMeetingLines = async () => {
    
      try{
          
          setFire(true)

          const response = await axios.get(`${baseUrl}/std_private_meetings.php`,{
            params:{
              stdId:location.state.stdId,
              code:location.state.code
            }
          })
                      
          if(Array.isArray(response.data) && response.data.length ){ 

            setLines(response.data)                        
          }
          else{  

            setLines([]);                                    
          }                              
      } 
      catch (error) {
        set_alert(error.message,'ERROR');        
      } finally{
        setFire(false)
      }       
    }

    fetchMeetingLines();
        
  },[flag]);
//-------------------------------------------------------------------------------------------
 
  const updateTotal = (lessonDtls,price) => {

    setTotal(prev=>prev+price)
        
    if(price>0){      
      setLessonsArr(current => [...current,lessonDtls]);                          
    }
    else{  
      const newList = lessonsArr.filter((lesson) => lesson.id !== lessonDtls.id);
      setLessonsArr(newList);                                         
    }
  }
//-------------------------------------------------------------------------------------------

  const addLine=(line)=>{
  
    if(lines.length<5){
      setLines((prevArr)=> [...prevArr,line]);
      setRefresh(prev=>prev=!prev)
    }
  }
//-------------------------------------------------------------------------------------------

  const removeMeetingLine= (id) => {
                                  
    const newList = lines.filter((line) => line.lessonId !== id);
    setLines(newList);
    setRefresh(prev=>prev=!prev)            
  }
//-------------------------------------------------------------------------------------------

  const prepareReceiptDtls = () => {

    const receipt = {
      customer_id:location.state.stdId,
      customer_name: props.name,
      customer_email:props.email, 
      items: lessonsArr,
      total_amount: total,
      credit:credit, 
      mode:'PRIVATE',
    }

    return receipt

  }
//-------------------------------------------------------------------------------------------  
 
  const toPaidStatus = async (payment) => {
    
    try {
                    
          let msg=""

          if(payment.method==='visa' && payment.status==='error'){
            throw new Error(payment.message)
          }

          if(payment.method!=='visa'){

            setFire(true)

            const receiptDtls = prepareReceiptDtls()
          
            const receipt = {
              transaction: {
                ConfirmationCode: payment.confirmationCode,
                method: payment.method,
                last4digits: payment.last4digits,
              },
              receiptDtls 
            }; 

            const receiptData=JSON.stringify(receipt)

            msg = await sendReceipt(receiptData)
            
          } else {
            msg=payment.message;
          }

          set_alert(msg,'SUCCESS')
          setTotal(0)
          setLessonsArr([])
          setCredit(prevCredit=>prevCredit-Math.min(prevCredit,total))
          setFlag(prev=>!prev)                    
    } 
    catch (error) {
      set_alert(`Error: ${error.message}`,'ERROR');

    } finally {
      setFire(false)
    }
  }
//-------------------------------------------------------------------------------------------

  const set_show_overlay= (value)=>{
    setShowOverlay(value);
  }
//-------------------------------------------------------------------------------------------

  const goToTop = () => {
    window.scrollTo({
        top:0,
        behavior: 'auto',
        behavior: 'smooth',  
    })
  }
//-------------------------------------------------------------------------------------------

  const set_alert = (msg,status) =>{
    setAlert({
      msg:msg,
      status:status,
    })
    goToTop()
  }
//-------------------------------------------------------------------------------------------


  return ( 

    <div className="container mb-4" style={{ minHeight: `${screenHeight}px` }}>

      {showOverlay && 
        <Box 
          position="fixed" 
          top={0} 
          left={0} 
          width="100%" 
          height="100%" 
          bgcolor="rgba(0,0,0,0.5)" 
          zIndex={9999}
        >
        </Box>
      }
                    
      <div className="mt-4">
        <PageTitle text="תיאום מפגשים"/>
      </div>
                                        
      <div className="mt-5 ms-2">  
        <AddMeetingLine 
          add={addLine} 
          tchId={location.state.tchId} 
          stdId={location.state.stdId} 
          code={location.state.code}
          price={location.state.price} 
          teacher={location.state.teacher}
          subject={location.state.subject}
          refresh={refresh}
          turnFireOn={turnFireOn}
          turnFireOff={turnFireOff}
          alert={set_alert}
        /> 
      </div> 
      
      <div className="mt-4 mb-4">          
        <Divider>
          <Chip 
            label={location.state.subject}  
            size="small" 
          />
        </Divider>
      </div>

      <div class="position-relative">                                                         
        
        {fire?
          <div className="position-absolute top-50 start-50 translate-middle-x mt-5" style={{zIndex: "1"}}>
              <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
          </div>
          :
          null
        }

        {
          alert.msg != null ? (
            <div className="mb-3" dir="ltr">
              <Alert
                severity={alert.status.toLowerCase()} // Set severity based on the alert status ('success', 'error', etc.)
                variant="outlined"
                onClose={() => {
                  setAlert({ msg: null, status: 'SUCCESS' }); // Reset alert
                }}
              >
                <AlertTitle>
                  <span className="bold-text">
                    {alert.status === 'SUCCESS' ? 'Success' : 'Error'}
                  </span>
                </AlertTitle>
                <span className="bold-text">{alert.msg}</span>
              </Alert>
            </div>
          ) : null
        }

        <div className="d-flex flex-column gap-2">
          {
            lines.map((req,index)=>(                
              <StdMeetingReqBox
                index={index}
                bgcolor={index%2===0?"rgb(240, 245, 240)":"rgb(243, 240, 245)"}                                   
                photo={location.state.photo}
                subject={location.state.subject} 
                teacher={location.state.teacher}
                date={req.date}
                start={req.start} 
                end={addMinutes(req.start,req.num*50)}                                
                lessonId={req.lessonId}
                statusDate={req.statusDate}
                num={req.num}              
                price={req.price} 
                status={req.status} 
                removeMeetingLine={removeMeetingLine}
                updateTotal={updateTotal}
                checked={lessonsArr.some(lesson=>lesson.id===req.lessonId)}   
                turnFireOn={turnFireOn}
                turnFireOff={turnFireOff}  
                alert={set_alert}                                                                                                                       
              />                         
            ))
          } 
        </div>
                                                                                                                                              
      </div>

      {lines.length>0?
        <>                                                                                                              
          <div className="mt-4">
            <Divider height="2px" color="rgb(255, 0, 0,0.2)"/>
          </div>
        
          <div className="row mt-4 gap-3 gap-lg-0">
                                
            <div className="col-lg order-1 order-lg-0">            

              <PaymentBox  
                subTotal={total}
                total={(total-Math.min(credit,total))*(1+Number(process.env.REACT_APP_TAX))} 
                toPaidStatus={toPaidStatus} 
                prepareReceiptDtls={prepareReceiptDtls}
                userId={props.id}
                set_show_overlay={set_show_overlay}
                alert={set_alert}                
              />
            
            </div> 

            <div className="col-lg order-0 order-lg-1">

              <div> 
                <Bill 
                  total={total} 
                  credit={credit}
                /> 
              </div>
              
            </div> 

          </div>
        </>
        :
        null
      }
    </div>                                                      
  )  
};
  
export default MeetingsCoordination;  
