import React, { useState,useEffect } from "react";
import {Modal,Table} from 'react-bootstrap'; 
import Button from '@mui/material/Button';
import { FaSchool  } from 'react-icons/fa';
import { FcApproval } from "react-icons/fc";
import { MdCancel } from "react-icons/md";
import { FaRegEyeSlash } from "react-icons/fa";
import AddInstitute from "./add_institute";
import axios from 'axios';
import MsgBox from "./msg_box";
import '../Components/forms.css';

const baseUrl = process.env.REACT_APP_BASE_URL;

const InstitutesList=(props)=>{ 

    const [lines,setLines]=useState([]);   
    const [show,setShow]=useState(false);
    const [refresh,setRefresh]=useState(false);
    const [count,setCount]=useState(0);
    const [msg, setMsg] = useState("");
    const [open, setOpen] = useState(false);

    useEffect(()=>{

      const fetchInstituteLines = async () => {
        try{
            const response = await axios.get(`${baseUrl}/tch_institutes_list.php?tchId=${props.tchId}`)
                                
            if(Array.isArray(response.data) && response.data.length ){          
              setLines(response.data) 
              setCount(response.data.length)            
            }
            else{                       
              setLines([]);                         
            }                              
        } 
        catch (error) {
          alert(error.message,'ERROR') ;         
        }        
      }

      fetchInstituteLines();

    },[]);
//-------------------------------------------------------------------------------------------

  const alert = (msg,status) => {

    ToggleInstitutes()
    props.alert(msg,status)
  }
//-------------------------------------------------------------------------------------------

  function ToggleInstitutes(){ 
    setShow((f)=>!f)
  };
//-------------------------------------------------------------------------------------------

    const addLine=(item)=>{
      
      setLines((prevArr)=> [...prevArr,{id:item.id,name:item.name,Comment:item.comment,status:item.status}]);
      setCount(prev=>prev+1)
     
    }
//-------------------------------------------------------------------------------------------

    const removeLine = async (id) => {

      try{
            const response = await axios.get(`${baseUrl}/delete_tch_institute_line.php`,{
              params: {
                id:id
              }
            })

            if( response.data.id > 0 ){
              const newList = lines.filter((line) => line.id !== id);  
              setLines(newList);
              setRefresh(prev=>!prev)
              setCount(prev=>prev-1)
            }           
            else
              alert("ביטול שיוך מורה למרכז לימוד נכשל!!",'ERROR')        
      } 
      catch (error) {
        alert(error.message,'ERROR') ;
      }      
    }
//-------------------------------------------------------------------------------------------
    
    return (  
      <div>        
        <button 
          type="button" 
          class="btn btn-outline-secondary btn-sm daily-prog-btn" 
          onClick={ToggleInstitutes} style={{width:"100%"}}
        >
            שיוך למרכז לימוד
        </button>
        
        {open && <MsgBox closeBtn={true} title="הערות" content={msg} close={() => setOpen(false)} />}
        
        <Modal 
              show={show}  
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"             
        >  
          <Modal.Header style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}>
            <div className="d-flex gap-2 align-items-center">
              <div><FaSchool size={30} /></div>
              <div className="mt-2"><h5>שיוך למרכז לימוד</h5></div>
            </div>
            <button
                type="button"
                className="custom-modal-close"
                aria-label="Close"
                onClick={ToggleInstitutes}
            >
                &times;  {/* Close "X" icon */}
            </button>
          </Modal.Header>  
          <Modal.Body>
            <div className="container mt-2 p-2">
              
              <AddInstitute  add={addLine} tchId={props.tchId} refresh={refresh} count={count}/>
             
              <div class="mt-4">                        
                <Table hover >
                  <thead className=" text-center">
                    <tr>
                      <th>#</th>
                      <th>מרכז לימוד</th>                     
                      <th>מחיקה</th>
                      <th>סטטוס</th>
                      <th>הערות</th>
                    </tr>
                  </thead>
                  <tbody className=" text-center">
                    {
                      lines.map((row,index)=>(
                        <tr>                          
                          <td>{index+1}</td>
                          <td>{row.name}</td>                        
                          <td><button type="button" class="btn-close" onClick={() => removeLine(row.id)}></button></td>
                          <td>{row.status==='NO_RESPONSE'?<FaRegEyeSlash color="red" size={25}/>:row.status==='APPROVED'?<FcApproval size={25}/>:<MdCancel color="red" size={25}/>}</td>
                          <td>                                                       
                              <button 
                                type="button" 
                                class="btn btn-warning btn-sm" 
                                style={{borderRadius:"20px",width:"60px"}} 
                                onClick={() => {
                                  setOpen(true);
                                  setMsg(row.comment);                                  
                                }}
                                disabled={row.status==='NO_RESPONSE'}
                              >
                                הצג
                              </button>                              
                            
                          </td>                          
                        </tr>                          
                      ))
                    } 
                  </tbody>                                                                                                                                 
               </Table>
              </div>

            </div> 

          </Modal.Body>  
          <Modal.Footer>              
            <Button 
              variant="contained"  
              color="error"  
              size="small" 
              onClick={ToggleInstitutes} 
              className="me-3"
            >
              סגור
            </Button>  
          </Modal.Footer>  
        </Modal> 
      </div>        
    )  
  } 
  
export default InstitutesList;  
