
import React, {useState,useEffect} from "react";
import axios from 'axios';
import {Modal} from 'react-bootstrap'; 
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CourseIcon from "./course_icon";
import { BsImages  } from 'react-icons/bs';
import Pagination from '@mui/material/Pagination';
import ReactLoading from 'react-loading';
import UploadPhotoFile from "./upload_photo";
import { pairSubPhoto } from "./db";
import SelectSuject from "./select_subject";

const baseUrl = process.env.REACT_APP_BASE_URL;
const win=8;

const SubjectPhotos=(props)=>{ 
          
    const [show,setShow]=useState(false);    
    const [checked,setChecked]=useState(0);
    const [page, setPage] = useState(1);
    const [pics,setPics]=useState([]);
    const [fire,setFire]=useState(false);
   
    const [subDtls, setSubDtls] = useState({         
      id:0,
      name:''    
    });
    
    useEffect(()=>{

      const fetchPhotos = async () => {
        try {

          const response = await axios.get(`${baseUrl}/photos.php`,{
            params: {                        
              sub_id:subDtls.id                                                                         
            }
          })
                              
          if(Array.isArray(response.data) && response.data.length)
            setPics(response.data);
          else
            setPics([]);
        } 
        catch (error) {
  
          alert(error.message,'ERROR') ; 
        }        
      }

      fetchPhotos();
                       
    },[subDtls.id]);
//-------------------------------------------------------------------------------------------

  const alert=(msg,status)=>{

    props.alert(msg,status)
    closeSubjectPicsModal()
  }
//-------------------------------------------------------------------------------------------

    function openSubjectPicModal(){ 
      setShow(true)
    };
//-------------------------------------------------------------------------------------------

    function closeSubjectPicsModal(){ 
      setShow(false);
     
    };
//-------------------------------------------------------------------------------------------    

    const getValueFromPic=(value)=>{
      setChecked(value);
    }
//-------------------------------------------------------------------------------------------

    const handleChange = (_,page)=> {
      
      if (page !== null) 
        setPage(page);     
    }
//-------------------------------------------------------------------------------------------

  const isLoding = (flag) => {

    setFire(flag)
  }
//-------------------------------------------------------------------------------------------
  
  const addPhoto = async (url)=>{
        
    const id = await pairSubPhoto(subDtls.id,url)
    setPics((prevArr)=> [...prevArr,{id:id,file:url}]);
    return(id)
     
  }
//-------------------------------------------------------------------------------------------

  const getSubject=(id,name)=>{
          
    setSubDtls({['id']:id,['name']:name})
    setPage(1)
    
  }
//-------------------------------------------------------------------------------------------

  const extractFilenameFromUrl=(url)=> {
    
    const decodedUrl = decodeURIComponent(url);
    const urlObj = new URL(decodedUrl);
    
    const pathname = urlObj.pathname;
    const filename = pathname.split('/').pop();
    
    return filename;
  }
//-------------------------------------------------------------------------------------------

  const deletePhoto = async () => {

    const photoToDelete = pics.find(pic => pic.id === checked);
    
    try {
        setFire(true)
        const response = await axios.get(`${baseUrl}/delete_subject_icon.php`, {
            params: { 
              id: checked,
              file:extractFilenameFromUrl(photoToDelete.file) 
            }
        });

        if (response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        const updatedPics = pics.filter(pic => pic.id !== checked);
        setPics(updatedPics);

        setChecked(0);

        const newTotalPages = Math.ceil(updatedPics.length / win);

        if (page > newTotalPages) {
            setPage(newTotalPages > 0 ? newTotalPages : 1);
        }

    } catch (error) {
      alert(`Error deleting photo: ${error.message}`,'ERROR');
    } finally{
      setFire(false)
    }
  }
//-------------------------------------------------------------------------------------------

    return (  
      <div>        
        <a className='nav-item' onClick={openSubjectPicModal}>הוספת תמונות</a>
        <Modal 
            show={show} 
            onHide={closeSubjectPicsModal} 
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"            
        >  
            <Modal.Header closeButton style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}>
              <div className="d-flex gap-2 align-items-center">
                <div><BsImages size={30} /></div>
                <div className="mt-1"><h4>הוספת תמונות</h4></div>
              </div>             
            </Modal.Header>

            <Modal.Body>
              <div className="container position-relative">

                {fire?
                  <div className="position-absolute top-50 start-50 translate-middle-x" style={{zIndex: "1",top:"300",left:'300'}}>
                      <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
                  </div>
                  :
                  null
                } 

                <div className="row justify-content-center gap-2" style={{ minHeight: '100%' }}>
                  
                  <div className="col-lg-3 d-flex flex-column justify-content-center align-items-center border rounded pb-3" style={{borderColor:"#d3d3d3"}}>                    
                    <UploadPhotoFile 
                      isLoding={isLoding} 
                      addPhoto={addPhoto}
                      alert={alert}
                    />
                  </div> 

                  <div className="col-lg-8 border rounded" style={{borderColor:"#d3d3d3"}}>

                    <div className="label-100-wrap mt-4">
                      <label class="form-label label-100" for="subjects">מקצוע</label>              
                      <div style={{width:"150px"}}>
                          <SelectSuject 
                            setSubject={getSubject}
                            alert={alert}
                           />
                      </div>
                    </div>
                   
                    <div className="d-flex flex-wrap gap-1 mt-2" style={{ minHeight: 210, height: 'auto' }}>
                      {
                        pics.slice((page-1)*win,(page-1)*win+win).map((pic)=>(                  
                          <CourseIcon 
                            value={pic.id} 
                            checked={checked} 
                            icon={pic.file} 
                            getValueFromIcon={getValueFromPic}
                            alert={alert}
                          />
                      ))}                               
                    </div>
                                                                         
                    <div className="mt-3 mb-2 d-flex justify-content-between align-items-center" dir="ltr">

                      <IconButton 
                        aria-label="delete" 
                        size="large"                                                
                        onClick={deletePhoto}
                        disabled={checked===0}
                        alert={alert}
                      >
                        <DeleteIcon 
                          fontSize="inherit"
                          alert={alert}
                        />
                      </IconButton>

                      <Pagination 
                        count={Math.ceil(pics.length / win)} 
                        variant="outlined" 
                        shape="rounded" 
                        color="primary" 
                        page={page} 
                        onChange={handleChange} 
                      />

                    </div>                    
                  </div>
                </div>
              </div>  
            </Modal.Body>

            <Modal.Footer>  
              <div className="d-flex gap-2 me-3"> 

                <Button 
                  variant="contained" 
                  color="error"   
                  size="small" 
                  onClick={closeSubjectPicsModal}
                >
                  סגור
                </Button> 

              </div>  
            </Modal.Footer>
              
        </Modal> 
      </div>        
    )  
  } 
  
export default SubjectPhotos;  
