import React, { useState } from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import PersonAdd from '@mui/icons-material/PersonAdd';
import StdsInLessonReport from './stds_in_lesson_report';
import UpdateLessonSubject from './update_lesson_subject';
import UpdateLesson from './update_lesson';
import { cancelLesson } from './db';
import MsgBox from './msg_box';

const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          width: '200px', // Set the width you need
        },
      },
    },
  },
});

const FontAwesomeSvgIcon = React.forwardRef((props, ref) => {
  const { icon } = props;

  const {
    icon: [width, height, , , svgPathData],
  } = icon;

  return (
    <SvgIcon ref={ref} viewBox={`0 0 ${width} ${height}`}>
      {typeof svgPathData === 'string' ? (
        <path d={svgPathData} />
      ) : (
        /**
         * A multi-path Font Awesome icon seems to imply a duotune icon. The 0th path seems to
         * be the faded element (referred to as the "secondary" path in the Font Awesome docs)
         * of a duotone icon. 40% is the default opacity.
         *
         * @see https://fontawesome.com/how-to-use/on-the-web/styling/duotone-icons#changing-opacity
         */
        svgPathData.map((d, i) => (
          <path style={{ opacity: i === 0 ? 0.4 : 1 }} d={d} />
        ))
      )}
    </SvgIcon>
  );
});

FontAwesomeSvgIcon.propTypes = {
  icon: PropTypes.any.isRequired,
};

const LessonMenu=(props)=> {
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem,setSelectedItem]=useState(0);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }
//-------------------------------------------------------------------------------------------

  const handleCloseMenu = (number) => {
    setSelectedItem(number);
    setAnchorEl(null)
  }
//-------------------------------------------------------------------------------------------

  const onAction= async () => {

    try{  
      
      const msg=await cancelLesson(props.lessonId,props.mode)
      props.alert(msg,'SUCCESS')
      props.changeToCancelStatus()
    } catch(error) {
       
      props.alert(error.message,'ERROR')
    }

  }
//-------------------------------------------------------------------------------------------

  const Close = () => {
    handleCloseMenu(0)
  }
//-------------------------------------------------------------------------------------------
    
  return (

    <>
      { selectedItem===1?      
          <div>
            <UpdateLesson  
              link={false} 
              resetMenu={handleCloseMenu}
              lessonId={props.lessonId}
              tchId={props.tchId}
              updateLessonDtls={props.updateLessonDtls}
              index={props.index}
              alert={props.alert}
            />
          </div>
        :selectedItem===2?
        <div> 
          <UpdateLessonSubject        
            link={false} 
            resetMenu={handleCloseMenu}         
            mode={props.mode}
            lessonId={props.lessonId}            
            updateLessonSubject={props.updateLessonSubject}
            index={props.index}
            alert={props.alert}
          />
        </div>      
        :selectedItem===3?
          <div> 
            <StdsInLessonReport  
              link={false} 
              resetMenu={handleCloseMenu} 
              code={props.code} 
              lessonId={props.lessonId}
              alert={props.alert}
            />
          </div>
        :selectedItem===4?
          <div>
            <MsgBox 
              link={false} 
              title="ביטול שיעור"
              close={Close} 
              onAction={onAction} 
              content="האם אתה בטוח שברצונך לבטל את השיעור?" 
            />
          </div>
        :null 
      }
    
      <React.Fragment>
        <Box
          sx={{
            '& > :not(style)': {
              m: 0.5,
            },
          }}
        >      
          <IconButton  onClick={handleClick}>
              <MoreVertIcon fontSize='medium' />
          </IconButton>      
        </Box> 

        <ThemeProvider theme={theme}>
          <Menu
            dir="ltr"
            anchorEl={anchorEl}
            id="account-menu"
            open={open}        
            onClose={() => handleCloseMenu(0)}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', 
                mt:1,        
                
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  left: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          >
            
            <>
              {props.mode === 'GROUP' && !props.endLesson && !props.cancelled && (
                <MenuItem className='d-flex align-items-center justify-content-between' onClick={() => handleCloseMenu(1)}>                      
                  <div><EditRoundedIcon/></div>
                  <div>עריכה</div>                                                        
                </MenuItem>
              )}

              {props.mode !== 'GROUP' && !props.endLesson && !props.cancelled && (
                <MenuItem className='d-flex align-items-center justify-content-between' onClick={() => handleCloseMenu(2)}>
                  <div><PersonAdd/></div>             
                  <div>נושא שיעור</div>
                </MenuItem>
              )}

              <MenuItem className='d-flex align-items-center justify-content-between' onClick={() => handleCloseMenu(3)}>
                <div><PeopleOutlineIcon/></div> 
                <div>דוח משתתפים</div> 
              </MenuItem>

              {!props.endLesson && !props.cancelled && (
                <MenuItem className='d-flex align-items-center justify-content-between' onClick={() => handleCloseMenu(4)}>
                  <div><CancelOutlinedIcon/></div>
                  <div>ביטול שיעור</div>
                </MenuItem>
              )}
              
            </>
          </Menu>
        </ThemeProvider>    
      </React.Fragment> 
    </>  
  );
}
export default LessonMenu; 
