import  React, {useState,useEffect,useRef} from "react";
import PageTitle from "../Components/page_title";
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import Divider from '@mui/material/Divider';
import ReportSummary from "../Components/report_summary";
import ReportByMonth from "../Components/report_by_month";
import axios from 'axios';
import { addMinutes } from "../Components/time";
import InvoiceLine from "../Components/invoce_line";
import ReactLoading from 'react-loading';
import { Alert } from "@mui/material";
import AlertTitle from '@mui/material/AlertTitle';
import '../Components/alert.css';

const baseUrl = process.env.REACT_APP_BASE_URL; 

const TchInvoices = (props) => {

  const [invoceItems,setInvoceItems]=useState([]);
  const [refresh,setRefresh]=useState(false);   
  const total = useRef(0);
  const [screenHeight, setScreenHeight] = useState(0);
  const [fire,setFire]=useState(false);

  const [alert, setAlert] = useState({
    msg: null,
    status: 'SUCCESS',
  });

  useEffect(() => {
    setScreenHeight(window.innerHeight-200);
  }, []);
//-------------------------------------------------------------------------------------------
                 
  const  setDateRange = async (sDate,eDate) => {
    
    total.current=0

    try {
      
      setFire(true)
      const response = await axios.get(`${baseUrl}/tch_lessons_report.php`,{
        params: {                        
          userId:props.userId, 
          sDate:sDate,
          eDate:eDate                                                                
        }
    })
              
      if(Array.isArray(response.data) && response.data.length){
        setInvoceItems(response.data)
      }
      else{
        setInvoceItems([])
      }
        
    } 
    catch (error) {

      set_alert(error.message,'ERROR') ;
      
    } finally {
      setFire(false)
    }

    setRefresh(prev=>!prev)
    
  }     
//-------------------------------------------------------------------------------------------
  
  const updateTotal =(price) =>  {
    
    total.current=total.current+price      
  }
//-------------------------------------------------------------------------------------------

  const goToTop = () => {
    window.scrollTo({
        top:0,
        behavior: 'auto',
        behavior: 'smooth',  
    })
  }
//-------------------------------------------------------------------------------------------

  const set_alert = (msg,status) =>{
    setAlert({
      msg:msg,
      status:status,
    })
    goToTop()
  }
//-------------------------------------------------------------------------------------------

  return (

    <div className="container mb-4" style={{ minHeight: `${screenHeight}px` }}>
                      
        <div className="mt-4">
          <PageTitle text="דוח שעות"/>
        </div>
        
        <div className="mt-5 ms-2">
          <ReportByMonth setDateRange={setDateRange}/>
        </div>
                 
        <div className="mt-4 mb-4">
          <Divider></Divider> 
        </div>

        <div className="position-relative">

          {fire?
            <div className="position-absolute top-50 start-50 translate-middle-x mt-5" style={{zIndex:"1"}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
            :
            null
          } 

          {
            alert.msg != null ? (
              <div className="mb-2" dir="ltr">
                <Alert
                  severity={alert.status.toLowerCase()} // Set severity based on the alert status ('success', 'error', etc.)
                  variant="outlined"
                  onClose={() => {
                    setAlert({ msg: null, status: 'SUCCESS' }); // Reset alert
                  }}
                >
                  <AlertTitle>
                    <span className="bold-text">
                      {alert.status === 'SUCCESS' ? 'Success' : 'Error'}
                    </span>
                  </AlertTitle>
                  <span className="bold-text">{alert.msg}</span>
                </Alert>
              </div>
            ) : null
          }

          {invoceItems.map((item,index)=>(                                                                                                          
            <div className={index===0?"mt-0":"mt-3"}>
              <InvoiceLine
                index={index}
                bgcolor={index%2===0?"rgb(240, 245, 240)":"rgb(243, 240, 245)"} 
                teacher={item.lessonId!==null?item.teacher:item.p_teacher}                                
                subject={item.lessonId!==null?item.subject:item.p_subject} 
                date={item.lessonId!==null?item.date:item.p_date}
                start={item.lessonId!==null?item.start:item.p_start} 
                end={item.lessonId!==null?addMinutes(item.start,item.num*50):addMinutes(item.p_start,item.p_num*50)}                
                photo={item.photo}                               
                price={item.lessonId!==null?item.price:item.p_price}
                amount={item.lessonId!==null?item.num:item.p_num}
                updateTotal={updateTotal}  
                alert={set_alert}                                                                                                                                                                                 
              />              
            </div>            
          ))}
        </div>
      
      {invoceItems.length>0?
        <>                                                                                                              
          <div className="mt-4">
            <Divider></Divider> 
          </div>
        
          <div className="row mt-4 gap-3 gap-lg-0">
                                            
            <div className="col-lg order-0 order-lg-1">

              <div> 
                <ReportSummary 
                  total={total.current}
                  alert={set_alert}
                /> 
              </div>
              
            </div> 

          </div>
        </>
        :
        null
      } 
  
    </div>
    
  );
};

export default TchInvoices;


