import { Navbar, Nav, NavDropdown} from 'react-bootstrap';
import { Outlet, Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import '../Layouts/header.css'
import RequestsBadge from '../Components/requests_badge';
import LogoutIcon from '@mui/icons-material/Logout';
import Help from '../Components/help';

const TchHeader = (props) => {
  return (
    <>
       <Navbar className='position-relative' collapseOnSelect expand='lg' bg='dark' variant='dark'>
          
          <Navbar.Toggle className='ms-3' aria-controls='responsive-navbar-nav'/>
             
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className="nav mt-4 mt-lg-2 mb-4 mb-lg-2 ms-4  align-items-start align-items-lg-center">
                <Avatar src={props.avatar} />
                <Link className='nav-item' to="/"><HomeIcon/></Link>
                <Link className='nav-item' to="/courses">קורסים שלי</Link>
                <Link className='nav-item' to="/tchclassonline">כיתה מכוונת</Link>
                <Link className='nav-item' to="/meetingsreq"><RequestsBadge count={props.requestsNum}/></Link>
                <Link className='nav-item' to="/tchreport">דוח שעות</Link>
                <Link className='nav-item' to="/tchprofile">פרופיל</Link>
                <Link className='nav-item' to="/contact">צור קשר</Link>
                
                <NavDropdown 
                  title="עזרה" 
                  id="navbarScrollingDropdown" 
                  
                >
                  <NavDropdown.Item 
                    style={{ display: 'flex', alignItems: 'center', gap: '8px' }} 
                  >
                    <i className="fas fa-chalkboard-teacher"></i> 
                    <Help btn_txt="מדריך מורה" help="teacher_guide.pdf" />
                  </NavDropdown.Item>

                  <NavDropdown.Divider /> 

                  <NavDropdown.Item 
                    style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                  >
                    <i className="fas fa-user-graduate"></i> 
                    <Help btn_txt="מדריך תלמיד" help="student_guide.pdf" />
                  </NavDropdown.Item>
                </NavDropdown>
                {/*<Link className='nav-item' to="/messages"><MessageBadge/></Link>*/}
                <a className='nav-item' onClick={() => props.disconnect()}><LogoutIcon/><b> יציאה</b></a>
            </Nav> 
          </Navbar.Collapse>

          <div className='tch_brand-pos'>
            <Navbar.Brand href="/" className="text-light">
              <b>DoroosOnline</b>
            </Navbar.Brand>         
          </div>
          
      </Navbar>

      <Outlet />

    </>
  )
};

export default TchHeader;