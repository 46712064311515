import React, { useState,useEffect} from "react";
import axios from 'axios';
import './popup_modals.css';

const baseUrl = process.env.REACT_APP_BASE_URL;

const AddScheduleLine=(props)=>{ 
    
    const lessonsNum=["1","2","3","4"];
    const [maxNum,setMaxNum]=useState(0);
    const [times,setTimes]=useState([]);
    const [lessonDtls, setLessonDtls] = useState({        
        day:"א",
        time:0,
        num:1
    });
//-------------------------------------------------------------------------------------------

    useEffect(()=>{

        const fetchDailyProgram = async () => { 
                        
            try {
                
                const response = await axios.get(`${baseUrl}/get_pro_by_dow.php`,{
                    params: {
                        tchId:props.tchId,
                        dow:lessonDtls.day
                    }
                })
            
                if(response.data.status==='success'){
                    setTimes(response.data.times)
                    setLessonDtls(prevState => ({
                        ...prevState,
                        ['time']:response.data.times[0].id,
                        ['num']:1             
                    })) 
                }     
                else{

                    setTimes([]);

                    if (response.data.status==='error') {
                        throw new Error(response.data.message || 'An unknown error occurred');                        
                    }   
                }
                                               
          } 
          catch (error) {
  
            props.alert(error.message,'ERROR') ;
           
          }
          
        }
         
        fetchDailyProgram();
      
    },[lessonDtls.day,props.refresh]);
//-------------------------------------------------------------------------------------------
    
    useEffect(()=>{
        
        let index= times.findIndex(t => t.id.toString() === lessonDtls.time.toString())
        let count=1
        let flag=true
        
        if(index>-1){

            while(index < times.length-1 && flag){ 
            
                if( parseInt(times[index].id)+7 === parseInt(times[index+1].id))
                    count++                                                           
                else
                    flag=false
                  
     
                index++  
                                             
             }
             
             setMaxNum(count)

        }
        
    },[lessonDtls.time]);

//-------------------------------------------------------------------------------------------

    const handleChange = e => {
        const {name,value} = e.target;
        setLessonDtls(prevState => ({
            ...prevState,
            [name]:value            
        }));
    };
//-------------------------------------------------------------------------------------------
   
    const addNewLine = async () =>{  

        try{

            const index = times.findIndex(t => t.id.toString() === lessonDtls.time.toString());

            if (index === -1) {
                props.alert("Selected time not found in the schedule",'ERROR');
                return; 
            }
            const time = times[index].hour;

            const response = await axios.get(`${baseUrl}/new_line.php`,{
                params: {
                    code:props.code,
                    time:lessonDtls.time,
                    lessons_num:lessonDtls.num
                }
            })

        
            if(response.data.id>0){
                const line={id:response.data.id ,day:lessonDtls.day ,time:time, hour:lessonDtls.time, num:lessonDtls.num,};
                props.add(line);
            }
            else{
                props.alert("הוספת שורה חדשה ללוח הזמנים נכשלה !!")
            }
        }
        catch(error){
            props.alert(error.message,'ERROR')
        }                           
    }
//-------------------------------------------------------------------------------------------
        
    return (  
                
        <div className="d-flex flex-wrap gap-4 align-items-center"> 
            
            <div className="label-100-wrap">
                <label class="form-label label-100" for="L-day">יום</label>              
                <div dir="ltr">
                    <select class="form-select" id="L-day" name="day" value={lessonDtls.day} onChange={handleChange}>                                           
                        <option>א</option>
                        <option>ב</option>
                        <option>ג</option>
                        <option>ד</option>
                        <option>ה</option>
                        <option>ו</option>
                        <option>שבת</option>                                                                                       
                    </select> 
                </div>
            </div>

            <div className="label-100-wrap">
                <label class="form-label label-100" for="L-time">שעה</label>              
                <div dir="ltr">
                    <select class="form-select" id="L-time" name="time" value={lessonDtls.time} onChange={handleChange}>                                           
                        {times.length>0?
                            
                            times.map((t)=>(
                                <option value={t.id}>{t.hour}</option>
                            ))

                            :null                                                      
                        }                                         
                    </select> 
                </div>
            </div>

            <div className="label-100-wrap">
                <label class="form-label label-100" for="L-num">מס' שיעורים</label>              
                <div>
                    <select class="form-select" id="L-num" name="num" style={{width:"100px"}} value={lessonDtls.num} onChange={handleChange} >
                        {
                            lessonsNum.slice(0,maxNum).map((num,index)=>(
                                <option value={index+1}>{num}</option>
                            ))

                        }                                                                                                               
                    </select> 
                </div>
            </div>

            <div className="mt-c"> 
            {times.length>0?           
                <button class="btn btn-primary btn-sm" style={{borderRadius:"20px",width:"70px"}} onClick={addNewLine}>הוסף</button>
                :
                <button class="btn btn-primary btn-sm" style={{borderRadius:"20px",width:"70px"}} onClick={addNewLine} disabled>הוסף</button>
            }
            </div>                                                                              
            
        </div> 
                          
    )  
  } 
  
export default AddScheduleLine;  
