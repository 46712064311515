import { useState } from "react";
import {useNavigate} from 'react-router-dom';
import SignIn from "../Components/signin";
import ReactLoading from 'react-loading';
import { Alert } from "@mui/material";
import AlertTitle from '@mui/material/AlertTitle';
import '../Components/alert.css';

const AdminLogin = (props) => {

  const [fire,setFire]=useState(false);

  const [alert, setAlert] = useState({
    msg: null,
    status: 'SUCCESS',
  });

  const navigate = useNavigate();

  const turnFireOn=()=> setFire(true);
  const turnFireOff=()=>setFire(false);

//-------------------------------------------------------------------------------------------

  const goToTop = () => {
    window.scrollTo({
        top:0,
        behavior: 'auto',
        behavior: 'smooth',  
    })
  }
//-------------------------------------------------------------------------------------------

  const set_alert = (msg,status) =>{
    setAlert({
      msg:msg,
      status:status,
    })
    goToTop()
  }
//-------------------------------------------------------------------------------------------

  const connect=()=>{
    window.sessionStorage.setItem("user","ADMIN");
    navigate("/");
    props.connect();
  }
//-------------------------------------------------------------------------------------------
  
  return(
    <>

      <div className="d-flex flex-column align-items-center mt-5 mb-5" style={{minHeight:"350px"}}>

        <div className='login-width'>
          <span className="titleStyle">כניסה לחשבון</span>
        </div>             
        
        <div className="d-flex flex-column shadow gap-3 p-4 position-relative login-width">          

          {fire?
            <div className="position-absolute top-50 start-50 translate-middle-x mt-5" style={{zIndex:"1"}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
            :
            null
          }  

          {alert.msg != null ? (
            <div className="mt-2" dir="ltr">
              <Alert
                severity={alert.status.toLowerCase()} 
                variant="outlined"
                onClose={() => {
                  setAlert({ msg: null, status: 'SUCCESS' }); 
                }}
              >
                <AlertTitle>
                  <span className="bold-text">
                    {alert.status === 'SUCCESS' ? 'Success' : 'Error'}
                  </span>
                </AlertTitle>
                <span className="bold-text">{alert.msg}</span>
              </Alert>
            </div>
          ) : null} 

          <div>
            <SignIn 
              user="ADMIN" 
              connect={connect}
              turnFireOn={turnFireOn}
              turnFireOff={turnFireOff} 
              alert={set_alert}
            />
          </div> 
                                    
        </div> 

        <div className='mt-3'>
          <span>www.doroosonline.co.il</span>
        </div>

      </div>
    </>
                                
  )};
  
export default AdminLogin;
