import { useState,useEffect } from "react";
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

const SelectDocument=(props)=>{
    const [doc,setDoc]=useState({
        id:0,
        name:''
    });
    const [docs,setDocs]=useState([]);

    useEffect(()=>{

        const fetchDocuments = async () => {
          try {
            
            const response = await axios.get(`${baseUrl}/docs_by_user.php`,{
                params: {
                    userId:props.userId,
                    user:props.user,
                    schId:props.schId                                  
                }
            })
                        
            if(Array.isArray(response.data) && response.data.length){
                setDocs(response.data)
                setDoc(response.data[0].id,response.data[0].name)
                props.setDocument(response.data[0].id,response.data[0].name)
                props.isEmpty(false)                                             
            }                       
            else{            
                setDocs([])
                props.isEmpty(true) 
            }             
          } 
          catch (error) {
            props.alert(error.message,'ERROR');
          }
          
        }
  
        fetchDocuments();
                                   
      },[props.schId,props.refresh1,props.refresh2]);
//-------------------------------------------------------------------------------------------  

    return(

        <div>
            <select 
                class="form-select" 
                name="docs"
                style={{minWidth:"100px"}} 
                value={doc.id} 
                onChange={(e)=>{                    
                    setDoc(e.target.value,e.target.options[e.target.selectedIndex].text)
                    props.setDocument(e.target.value,e.target.options[e.target.selectedIndex].text)                    
                }}
            >                                           
                {
                    docs.map((d)=>(
                       <option value={d.id}>{d.name}</option>
                ))}                                                                                              
            </select> 
        </div>

    )
}

export default SelectDocument;
