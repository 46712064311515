import React, { useState } from "react";
import { Modal, Form } from 'react-bootstrap'; 
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import './popup_modals.css';
import '../Components/forms.css';

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
    comment: Yup.string()
        .min(5, 'Comment must be at least 5 characters')
        .required('Comment is required'),
});
//-------------------------------------------------------------------------------------------

const RejectBtn = (props) => { 
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState({});
    const [comment, setComment] = useState(""); 

    // Handle input changes for comment
    const handleChangeComment = (event) => {
        setComment(event.target.value);
    };
//-------------------------------------------------------------------------------------------

    // Validate form data using Yup
    const validateData = async () => {
        const formData = { comment };
        try {
            await validationSchema.validate(formData, { abortEarly: false });
            setErrors({});
            return true;
        } catch (err) {
            const newErrors = err.inner.reduce((acc, { path, message }) => {
                acc[path] = message;
                return acc;
            }, {});
            setErrors(newErrors);
            return false;
        }
    };
//-------------------------------------------------------------------------------------------

    // Form submission handler
    const handleSubmit = async (event) => {
        event.preventDefault();
        const isValid = await validateData();
        if (isValid) {

            const data = {
                docType:'none',
                comment: comment
            };
            
            // Convert to JSON string
            const jsonData = JSON.stringify(data);
            props.deny(jsonData);
            
            closeConfirmationBox();
        } 
    };
//-------------------------------------------------------------------------------------------

    // Open the confirmation modal
    const openConfirmationBox = () => { 
        setShow(true);
    };
//-------------------------------------------------------------------------------------------

    // Close the confirmation modal
    const closeConfirmationBox = () => { 
        setShow(false);
        setErrors({});
    };
//-------------------------------------------------------------------------------------------

    return (  
        <div>        
            {props.status !== "APPROVED" ? (
                <button 
                    type="button" 
                    className="btn btn-outline-danger btn-sm" 
                    style={{ borderRadius: "15px", width: "85px" }} 
                    onClick={openConfirmationBox}
                >
                    לא מאשר
                </button>
            ) : (
                <button 
                    type="button" 
                    className="btn btn-outline-success btn-sm" 
                    style={{ borderRadius: "15px", width: "85px" }} 
                    disabled
                >
                    לא מאשר
                </button>
            )}

            <Modal 
              show={show}                
              size="md"
              aria-labelledby="contained-modal-title-vcenter"            
            >  
                <Modal.Header style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}>
                    <div className="d-flex gap-2 align-items-center">
                        <div><HighlightOffIcon fontSize="large"/></div>
                        <div className="mt-1"><h5>{props.title}</h5></div>
                    </div>   
                    <button
                        type="button"
                        className="custom-modal-close"
                        aria-label="Close"
                        onClick={closeConfirmationBox}
                    >
                        &times;  {/* Close "X" icon */}
                    </button>                                              
                </Modal.Header>  
                
                <Form noValidate onSubmit={handleSubmit}>
                    <Modal.Body>
                        {/* Comment Area */}
                        <div className="label-100-wrap mt-4">                                           
                            <label className="form-label label-100" htmlFor="comment">הערות</label>                                                                                           
                            <textarea
                                className={`form-control ${errors.comment ? 'invalid' : ''}`}
                                id="comment"
                                name="comment"
                                rows="3"
                                value={comment}
                                onChange={handleChangeComment}
                            ></textarea>
                            {errors.comment && <p className="error">{errors.comment}</p>}
                        </div>
                    </Modal.Body>  

                    <Modal.Footer>
                        <div className="d-flex gap-3">                         
                            <Button variant="contained" color="error" size="small" onClick={closeConfirmationBox}>ביטול</Button>  
                            <Button variant="contained" color="success" size="small" type="submit">דחייה</Button>                         
                        </div>
                    </Modal.Footer> 
                </Form>        
            </Modal> 
        </div>        
    );  
};

export default RejectBtn;

