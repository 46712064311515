import { useState,useEffect} from 'react';
import {useNavigate } from 'react-router-dom';
import SelectUser from "../Components/select_user";
import SignIn from "../Components/signin";
import Social from "../Components/social";
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip'; 
import SignUp from "../Components/signup";
import ForgotPassword from "../Components/forgot_password";
import TermsAndConditionsModal from "../Components/terms";
import Checkbox from '@mui/material/Checkbox';
import ReactLoading from 'react-loading';
import { Alert } from "@mui/material";
import AlertTitle from '@mui/material/AlertTitle';
import '../Components/alert.css';
import './login.css'; 

const TchLogin = (props) => {
  const [slide,setSlide]=useState(0);
  const [checked,setChecked]=useState(false);
  const [user,setUser]=useState('');
  const [fire,setFire]=useState(false);
  const [termsAccepted, setTermsAccepted] = useState(true);
  const [screenHeight, setScreenHeight] = useState(0);

  const [alert, setAlert] = useState({
    msg: null,
    status: 'SUCCESS',
  });

  const navigate = useNavigate();
      
  const turnFireOn=()=> setFire(true);
  const turnFireOff=()=>setFire(false);

  useEffect(() => {
    setScreenHeight(window.innerHeight - 200); 
  }, []);
//-------------------------------------------------------------------------------------------

  const connect=()=>{
    navigate("/");
    props.connect();
  }
//-------------------------------------------------------------------------------------------
  
  const isChecked=(checked)=>{
    setChecked(checked);
  }
//-------------------------------------------------------------------------------------------

  const changeUser=(user)=>{
    setUser(user);
  }
//-------------------------------------------------------------------------------------------

  const goToTop = () => {
    window.scrollTo({
        top:0,
        behavior: 'auto',
        behavior: 'smooth',  
    })
  }
//-------------------------------------------------------------------------------------------

  const set_alert = (msg,status) =>{
    setAlert({
      msg:msg,
      status:status,
    })
    goToTop()
  }
//-------------------------------------------------------------------------------------------

  return(
    <>
      <div className="d-flex flex-column align-items-center mt-5 mb-5" style={{ minHeight: `${screenHeight}px`}}>

        <div className="login-width">
          {slide===0?
            <span className="titleStyle">כניסה לחשבון</span>
          :slide===1?
            <span className="titleStyle">הרשמה</span>
          :
            <span className="titleStyle">שחזור סיסמה</span> 
          }
        </div>             
        
        <div className="d-flex flex-column shadow gap-3 p-4 position-relative login-width">

          {fire?
            <div className="position-absolute top-50 start-50 translate-middle-x mt-5" style={{zIndex:"1"}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
            :
            null
          } 

          {alert.msg != null ? (
            <div className="mt-2" dir="ltr">
              <Alert
                severity={alert.status.toLowerCase()} 
                variant="outlined"
                onClose={() => {
                  setAlert({ msg: null, status: 'SUCCESS' }); 
                }}
              >
                <AlertTitle>
                  <span className="bold-text">
                    {alert.status === 'SUCCESS' ? 'Success' : 'Error'}
                  </span>
                </AlertTitle>
                <span className="bold-text">{alert.msg}</span>
              </Alert>
            </div>
          ) : null}            
                                            
          <>
            <div className="mt-2">
              <SelectUser 
                isChecked={isChecked}
                changeUser={changeUser}
              />
            </div>

            <div>
              <Divider/>  
            </div>
          </>
          
          {/*{slide!==2?  
            <>
              <div>
                <Social 
                  user={user} 
                  connect={connect} 
                  disabled={!checked || !termsAccepted}
                  turnFireOn={turnFireOn}
                  turnFireOff={turnFireOff}
                  mode={slide===0?'login':'signup'}
                  alert={set_alert}
                />
              </div>

              <div>
                <Divider> 
                  <Chip 
                    label='או'  
                    size="small" 
                  />
                </Divider>
              </div>

            </>
            :
            null
          }*/}  
                    
          <div>
            { slide===0?
                <SignIn 
                  user={user} 
                  connect={connect} 
                  disabled={!checked}
                  turnFireOn={turnFireOn}
                  turnFireOff={turnFireOff} 
                  alert={set_alert}
                />
              :slide===1?
                <>
                  <SignUp 
                    user={user} 
                    connect={connect}
                    disabled={!checked || !termsAccepted}
                    turnFireOn={turnFireOn}
                    turnFireOff={turnFireOff}
                    alert={set_alert}
                  />
                  <div className="d-flex align-items-center mt-2 gap-2">
                    <Checkbox 
                      checked={termsAccepted} 
                      color="secondary"                   
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                    />                
                    <TermsAndConditionsModal/>
                  </div>
                </>
              :
                <ForgotPassword 
                  user={user}  
                  disabled={!checked}
                  turnFireOn={turnFireOn}
                  turnFireOff={turnFireOff}
                  alert={set_alert}
                />
            } 
          </div> 
          
          { slide===0?
              <div className="d-flex justify-content-between">

                <div>
                  <a 
                    href="#" 
                    onClick={()=>{
                      setSlide(1)
                      setTermsAccepted(false)
                      setAlert({ msg: null, status: 'SUCCESS' }); 
                    }}
                  >
                    הרשמה
                  </a>
                </div>

                <div>
                  <a 
                    href="#" 
                    onClick = { () => {
                      setSlide(2)
                      setAlert({ msg: null, status: 'SUCCESS' }); 
                    }}
                  >
                    שכחתי סיסמה
                  </a>
                </div>

              </div>
            :
              <div >
                <a 
                  className='float-end' 
                  href="#" 
                  onClick={()=>{
                    setSlide(0)
                    setTermsAccepted(true)
                    setAlert({ msg: null, status: 'SUCCESS' }); 
                  }}
                >
                  כניסה לחשבון
                </a>
              </div>
          }
                
        </div> 

        <div className='mt-3'>
          <span>www.doroosonline.co.il</span>
        </div>

      </div>
    </>
                                
  )};

export default TchLogin;
