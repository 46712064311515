import React, { useState, useEffect } from "react";
import { Modal, Table } from 'react-bootstrap';
import Button from '@mui/material/Button';
import UploadDocs from "./upload_docs";
import { FcApproval } from "react-icons/fc";
import { MdCancel } from "react-icons/md";
import { FaRegEyeSlash } from "react-icons/fa";
import { VscFiles } from "react-icons/vsc";
import ReactLoading from 'react-loading';
import { storage } from "./firebase";
import { ref, deleteObject, getDownloadURL } from "firebase/storage";
import axios from 'axios';
import MsgBox from "./msg_box"; 
import '../Components/forms.css';

const baseUrl = process.env.REACT_APP_BASE_URL;

const DocsList = (props) => {
    const [lines, setLines] = useState([]);
    const [show, setShow] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [count, setCount] = useState(0);
    const [schId, setSchId] = useState(0);
    const [fire, setFire] = useState(false);
    const [pdf, setPdf] = useState(false);
    const [url, setUrl] = useState('');
    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState("");

    useEffect(() => {
        const fetchDocLines = async () => {
            try {
                const response = await axios.get(`${baseUrl}/user_docs_list.php`, {
                    params: {
                        userId: props.userId,
                        user: props.user,
                        schId: schId
                    }
                });

                if (Array.isArray(response.data) && response.data.length) {
                    setLines(response.data);
                    setCount(response.data.length);
                } else {
                    setLines([]);
                }
            } catch (error) {
                alert(error.message,'ERROR');
            }
        };

        fetchDocLines();
    }, [schId, show]);
//------------------------------------------------------------------------------------------- 

    const alert=(msg,status)=>{
        toggleDocs()
        props.alert(msg,status)
    }
//------------------------------------------------------------------------------------------- 
    const toggleDocs = () => {
        if (!show && pdf) setFire(true);
        setShow(prev => !prev);
    }
//------------------------------------------------------------------------------------------- 

    const getSchId = (id) => {
        setSchId(id);
    }
//------------------------------------------------------------------------------------------- 

    const isLoding = (flag) => {
        setFire(flag);
    }
//------------------------------------------------------------------------------------------- 

    const addLine = (item) => {
        setLines(prevArr => [...prevArr, {
            id: item.id,
            name: item.name,
            admin_comment: item.comment,
            sch_comment: item.comment,
            status: item.status,
            file: item.file
        }]);
        setCount(prev => prev + 1);
    }
//------------------------------------------------------------------------------------------- 

    const removeLine = async (id) => {
        try {
            const response = await axios.get(`${baseUrl}/delete_user_doc_line.php`, {
                params: {
                    id: id,
                    user: props.user
                }
            });

            if (!response.data || !response.data.status) {
                throw new Error('Invalid response from server');
            }

            if (response.data.status === 'success') {
                setLines(prevLines => prevLines.filter(line => line.id !== id));
                setRefresh(prev => !prev);
                setCount(prev => prev - 1);
            } else if (response.data.status === 'error') {
                throw new Error(response.data.message || 'Unknown error occurred');
            }

        } catch (error) {            
            throw error;
        }
    }
//------------------------------------------------------------------------------------------- 

    const deleteFile = async (file, id) => {
        try {
            const fileRef = ref(storage, `docs/${file}`);
            setFire(true); 
            await deleteObject(fileRef);
            await removeLine(id); 
        } catch (error) {
            alert(error.message || "An error occurred",'ERROR');             
        } finally {
            setFire(false); 
        }
    }
//------------------------------------------------------------------------------------------- 

    const displayPdf = (file) => {
        const fileRef = ref(storage, `docs/${file}`);
        getDownloadURL(fileRef).then((downloadUrl) => {
            setUrl(downloadUrl);
        }).catch((error) => {
            alert(error.message,'ERROR');
        });

        setPdf(true);
        setFire(true);
    }
//------------------------------------------------------------------------------------------- 

    const back = () => {
        setPdf(false);
        setFire(false);
    }
//------------------------------------------------------------------------------------------- 

    return (
        <div>
            <button type="button" className="btn btn-outline-secondary btn-sm daily-prog-btn" onClick={toggleDocs} style={{ width: "100%" }}>
                מסמכים
            </button>

            {open && <MsgBox closeBtn={true} title="הערות" content={msg} close={() => setOpen(false)} />}

            <Modal
                show={show}                
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}>
                    <div className="d-flex gap-2 align-items-center">
                        <div><VscFiles size={30} /></div>
                        <div className="mt-2"><h5>מסמכים</h5></div>
                    </div>
                    <button
                        type="button"
                        className="custom-modal-close"
                        aria-label="Close"
                        onClick={toggleDocs}
                    >
                        &times;  {/* Close "X" icon */}
                    </button>
                </Modal.Header>

                {pdf ? (
                    <Modal.Body>
                        <div className="position-relative" style={{ height: "350px" }}>
                            {fire && <div className="position-absolute top-50 start-50 translate-middle-x" style={{ zIndex: "1", top: "300", left: '300' }}>
                                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70} />
                            </div>}
                            <embed
                                type="application/pdf"
                                src={url}
                                width="100%"
                                height="100%"
                                onLoad={() => { setTimeout(() => { setFire(false) }, 1000) }}
                            />
                        </div>
                    </Modal.Body>
                ) : (
                    <Modal.Body>
                        <div className="container mt-4 p-2 position-relative">

                            {fire && <div className="position-absolute top-50 start-50 translate-middle-x" style={{ zIndex: "1", top: "300", left: '300' }}>
                                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70} />
                            </div>}

                            <UploadDocs 
                                add={addLine} 
                                userId={props.userId} 
                                user={props.user} 
                                refresh={refresh} 
                                count={count} 
                                setSchId={getSchId} 
                                isLoding={isLoding} 
                            />

                            <div className="mt-4">
                                <Table hover>
                                    <thead className="text-center">
                                        <tr>
                                            <th>#</th>
                                            <th>מסמך</th>
                                            <th>מחיקה</th>
                                            <th>סטטוס</th>
                                            <th>הערות</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        {lines.map((row, index) => (
                                            <tr key={row.id}>
                                                <td>{index + 1}</td>
                                                <td><button type="button" className="btn btn-link" onClick={() => displayPdf(row.file)}>{row.name}</button></td>
                                                <td><button type="button" className="btn-close" onClick={() => deleteFile(row.file, row.id)}></button></td>
                                                <td>
                                                    {row.status === 'NO_RESPONSE' ? <FaRegEyeSlash color="red" size={25} /> : row.status === 'APPROVED' ? <FcApproval size={25} /> : <MdCancel color="red" size={25} />}
                                                </td>
                                                <td>                                                    
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-warning btn-sm" 
                                                        style={{ borderRadius: "20px", width: "60px" }}
                                                        disabled={row.status === 'NO_RESPONSE'}
                                                        onClick={() => {
                                                            let alertMessage = "";
                                                            if (row.admin_comment !== null) {
                                                                alertMessage += `הערות של מנהל האתר:\n ${row.admin_comment}\n`;
                                                            }
                                                            if (row.admin_comment !== null && row.sch_comment !== null) {
                                                                alertMessage += "------------------------------------------\n";
                                                            }
                                                            if (row.sch_comment !== null) {
                                                                alertMessage += `הערות של מרכז הלימוד:\n ${row.sch_comment}`;
                                                            }
                                                            setOpen(true);
                                                            setMsg(alertMessage);
                                                        }}
                                                    >
                                                        הצג
                                                    </button>                                                     
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Modal.Body>
                )}

                <Modal.Footer>
                    <div className="d-flex gap-2 me-3">
                        <Button
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={toggleDocs}
                        >
                            סגור
                        </Button>
                        {pdf && <Button variant="contained" size="small" onClick={back}>חזרה</Button>}
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default DocsList;
