import  React, {useState,useEffect, useRef} from "react";
import PageTitle from "../Components/page_title";
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import ItemBox from "../Components/cart_item";
import Bill from "../Components/bill";
import PaymentBox from "../Components/payment_box";
import axios from 'axios';
import { addMinutes } from "../Components/time";
import ReactLoading from 'react-loading';
import { sendReceipt,getStudentCredit } from "../Components/db";
import { Box } from '@mui/material'; 
import MsgBox from "../Components/msg_box";
import Divider from '@mui/material/Divider';
import { Alert } from "@mui/material";
import AlertTitle from '@mui/material/AlertTitle';
import '../Components/alert.css';

const baseUrl = process.env.REACT_APP_BASE_URL; 

const ShoppingCart = (props) => {

  const [cartItems,setCartItems]=useState([]);
  const [lessonsArr,setLessonsArr]=useState([]);
  const [total,setTotal]=useState(0);
  const [credit,setCredit]=useState(0);
  const [fire,setFire]=useState(false);
  const [flag,setFlag]=useState(false)
  const [screenHeight, setScreenHeight] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);
  const [open, setOpen] = useState(false);
  const [alert,setAlert]=useState({
    msg:null,
    status:'SUCCESS',
  });

  const [alertMessage, setAlertMessage] = useState({
    msg:'',
    title:''
  });
  
  useEffect(() => {
    const fetchCredit = async () => {
        try {
            setFire(true);
            const stdCredit = await getStudentCredit(props.userId);
            setCredit(stdCredit);
        } catch (error) {
            set_alert(error.message,'ERROR');
        } finally {
            setFire(false);
        }
    };

    fetchCredit(); 
    setScreenHeight(window.innerHeight - 200);

  }, [props.userId]); 

//-------------------------------------------------------------------------------------------
      
  useEffect(()=>{
    
    const fetchShoppingCart = async () => {
      try {

        setFire(true)

        const response = await axios.get(`${baseUrl}/std_lessons_in_cart.php`,{
          params: {                         
            userId:props.userId,                                                                    
          }
        })
       
        if(Array.isArray(response.data) && response.data.length){
          setCartItems(response.data)
        }
        else{
          setCartItems([])
        }  
      } 
      catch (error) {

        set_alert(error.message,'ERROR');
        
      } finally {
        setFire(false)
      }

    }
  
    fetchShoppingCart();
        
  },[flag]);
//-------------------------------------------------------------------------------------------

  const goToTop = () => {
    window.scrollTo({
        top:0,
        behavior: 'auto',
        behavior: 'smooth',  
    })
  }
//-------------------------------------------------------------------------------------------

  const set_alert = (msg,status) =>{
    setAlert({
      msg:msg,
      status:status,
    })
    goToTop()
  }
//-------------------------------------------------------------------------------------------

  const updateTotal = (lessonDtls,price) => {

    setTotal(prev=>prev+price)
        
    if(price>0){      
      setLessonsArr(current => [...current,lessonDtls]);                          
    }
    else{  
      const newList = lessonsArr.filter((lesson) => lesson.id !== lessonDtls.id);
      setLessonsArr(newList);                                         
    }
  }
//-------------------------------------------------------------------------------------------

  const removeItem = (lessonId) => {
    const newList = cartItems.filter((item) => item.lessonId !== lessonId);
    setCartItems(newList);
    props.decreaseAmount(1);
  }
//-------------------------------------------------------------------------------------------

  const prepareReceiptDtls = () => {

    const receipt = {
      customer_id:props.userId,
      customer_name: props.name,
      customer_email:props.email, 
      items: lessonsArr,
      total_amount: total,
      credit:credit, 
      mode:'GROUP',
    }

    return receipt

  }
//-------------------------------------------------------------------------------------------  
  
  const toPaidStatus = async (payment) => {
   
    try {
                    
          let msg=""

          if(payment.method==='visa' && payment.status==='error'){
            throw new Error(payment.message)
          }

          if(payment.method!=='visa'){

            setFire(true)

            const receiptDtls = prepareReceiptDtls()
          
            const receipt = {
              transaction: {
                ConfirmationCode: payment.confirmationCode,
                method: payment.method,
                last4digits: payment.last4digits,
              },
              receiptDtls 
            }; 

            const receiptData=JSON.stringify(receipt)

            msg = await sendReceipt(receiptData)
            

          } else {
            msg=payment.message;
          }

          set_alert(msg,'SUCCESS') ;
          props.decreaseAmount(lessonsArr.length);
          setTotal(0)
          setLessonsArr([])
          setCredit(prevCredit=>prevCredit-Math.min(prevCredit,total))
          setFlag(prev=>!prev)                    
    } 
    catch (error) {
      set_alert(error.message,'ERROR');
    } finally {
      setFire(false)
    }
  }
//-------------------------------------------------------------------------------------------

  const set_show_overlay= (value)=>{
    setShowOverlay(value);
  }
//-------------------------------------------------------------------------------------------

  const setAlertMsg = (msg, title) => {
    setAlertMessage({ msg: msg, title: title });
    setOpen(true);
  }
//-------------------------------------------------------------------------------------------  

  const close = () => {
    setOpen(false)
  }
//-------------------------------------------------------------------------------------------  

  return (

    <div className="container mb-4" style={{ minHeight: `${screenHeight}px` }}>

        <div>
          {open?
            <MsgBox  
              closeBtn={true} 
              title={alertMessage.title} 
              content={alertMessage.msg} 
              close={close} 
            /> 
            :null
          }
        </div>

        {showOverlay &&  
          <Box 
            position="fixed" 
            top={0} 
            left={0} 
            width="100%" 
            height="100%" 
            bgcolor="rgba(0,0,0,0.5)" 
            zIndex={9999}
          >

          </Box>
        }
                      
        <div className="mt-4">
          <PageTitle text="סל הקניות שלי" sub={false}/>
        </div>

        <div>

          {fire?
            <div className="position-absolute top-50 start-50 translate-middle-x" style={{zIndex: "1",top:"1000px",left:'300'}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
            :
            null
          }

          {
            alert.msg != null ? (
              <div className="mt-4" dir="ltr">
                <Alert
                  severity={alert.status.toLowerCase()} // Set severity based on the alert status ('success', 'error', etc.)
                  variant="outlined"
                  onClose={() => {
                    setAlert({ msg: null, status: 'SUCCESS' }); // Reset alert
                  }}
                >
                  <AlertTitle>
                    <span className="bold-text">
                      {alert.status === 'SUCCESS' ? 'Success' : 'Error'}
                    </span>
                  </AlertTitle>
                  <span className="bold-text">{alert.msg}</span>
                </Alert>
              </div>
            ) : null
          }

          {cartItems.map((item,index)=>(
            <div className={index===0?"mt-4":"mt-2"}>              
              <ItemBox 
                index={index}
                bgcolor={index%2===0?"rgb(240, 245, 240)":"rgb(243, 240, 245)"} 
                teacher={item.teacher} 
                rating={item.rating} 
                ratings={item.counter}
                subject={item.subject} 
                date={item.date}
                start={item.start} 
                end={addMinutes(item.start,item.num*50)}                
                photo={item.photo}
                lessonId={item.lessonId}
                stdId={props.userId}
                price={item.price}
                num={item.num}
                removeItem={removeItem} 
                updateTotal={updateTotal} 
                checked={lessonsArr.some(lesson=>lesson.id===item.lessonId)}  
                alert={set_alert}                                                                                                                       
              />
            </div>            
          ))}
        </div>
      
      {cartItems.length>0?
        <>                                                                                                              
          <div className="mt-4">
            <Divider></Divider>
          </div>
        
          <div className="row mt-4 gap-3 gap-lg-0">
                                
            <div className="col-lg order-1 order-lg-0">            

              <PaymentBox 
                subTotal={total}
                total={(total-Math.min(credit,total))*(1+Number(process.env.REACT_APP_TAX))} 
                toPaidStatus={toPaidStatus} 
                prepareReceiptDtls={prepareReceiptDtls}
                userId={props.id}
                set_show_overlay={set_show_overlay}
                setErrorMsg={setAlertMsg} 
                alert={set_alert}
              /> 
            
            </div> 

            <div className="col-lg order-0 order-lg-1">
              <div> 
                <Bill 
                  total={total}  
                  credit={credit}
                  alert={set_alert} 
                /> 
              </div>
            </div> 

          </div>
        </>
        :
        null
      } 
  
    </div>
    
  );
};

export default ShoppingCart;


