import Carousel from 'react-bootstrap/Carousel';
import '../Components/carousel.css'
import banner_1 from '../Assets/Images/banner-website1.jpg'
import banner_2 from '../Assets/Images/banner-website2.jpg'
import banner_3 from '../Assets/Images/banner-website3.jpg'



const HomeCarousel = (props) => {

  return(

    <Carousel className="carousel" controls={false} interval={10000} pause={false} >
      
      <Carousel.Item className='carousel-item'>
        <img
          className="carousel-img"
          src={banner_1}
          alt="First slide"          
        />
      </Carousel.Item>
      
      <Carousel.Item className='carousel-item'>
        <img
          className="carousel-img"
          src={banner_2}
          alt="ٍSecond slide"          
        />
      </Carousel.Item>
      
      <Carousel.Item className='carousel-item'>
        <img
          className="carousel-img"
          src={banner_3}
          alt="Third slide"          
        />
      </Carousel.Item>
      
    </Carousel>

  );
};
export default HomeCarousel;
