import React, { useState } from "react";
import CourseMode from "./set_course_mode";
import SelectSuject from "./select_subject";
import SelectCategory from "./select_category";

const SearchBar=(props)=>{
        
    const [courseDtls, setCourseDtls] = useState({                
        subId:0,
        catId:0,
        mode:"GROUP"
    });

    const getSubject=(sub)=>{
        setCourseDtls(prevState => ({
            ...prevState,["subId"]: sub
        }))
    }
//-------------------------------------------------------------------------------------------

    const getCategory=(cat)=>{
        setCourseDtls(prevState => ({
            ...prevState,["catId"]: cat
        }))
    }
//-------------------------------------------------------------------------------------------

    const getCourseMode=(mode)=>{
                
        setCourseDtls(prevState => ({
            ...prevState,["mode"]: mode
        }))
    }
//-------------------------------------------------------------------------------------------

    const search=()=>{
        props.search(courseDtls.subId,courseDtls.catId,courseDtls.mode)
    }
//-------------------------------------------------------------------------------------------
       
    return (  
        <>        
            <div className="mt-3">
                <CourseMode 
                    setCourseMode={getCourseMode} 
                    mode={courseDtls.mode}
                    alert={props.alert}
                />
            </div> 
            
            <div class="d-flex flex-wrap align-items-center gap-4 mt-5">
                                                                                                                                                                                                                                    
                <div className="label-100-wrap">
                    <label class="form-label label-100" for="subjects">מקצוע</label>              
                    <div id="subjects">
                        <SelectSuject 
                            setSubject={getSubject} 
                            alert={props.alert}
                        />
                    </div>
                </div>

                <div className="label-100-wrap">
                    <label class="form-label label-100" for="catigories">קטגוריה</label>              
                    <div id="catigories">
                        <SelectCategory 
                            setCategory={getCategory} 
                            alert={props.alert}
                        /> 
                    </div>
                </div>

                <div>            
                    <button class="btn btn-primary btn-sm" style={{borderRadius:"20px",width:"70px"}} onClick={search} >חפש</button>
                </div>

            </div>
                        
        </>                           
    )  
  } 
  
export default SearchBar;  
