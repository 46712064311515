
import React, { useState,useEffect } from "react";
import '../Components/msg_box.scss'
import { updateMessageStatus } from "./db";
import DisplayMsg from "./display_msg";
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ReplyIcon from '@mui/icons-material/Reply';

const MessageBox = (props) =>{
  
  const [flag, setFlag] = useState(false);

  useEffect(()=>{
    setFlag(false)
  },[props.page])
//------------------------------------------------------------------------------------------- 
    
  const toggleMessage = async () => {
    setFlag((f)=>!f)
    if(props.status==='Delivered'){       
      const code=await updateMessageStatus(props.msgId,'Read') 
      if(code>0) {
        props.decreaseMessagesCount()
        props.updateMsgStatus(props.index,'Read')
      }
    }
  };
//------------------------------------------------------------------------------------------- 

  return (
    
    <div className="msg-box-container" style={{background:props.bgcolor}}>
                                            
      <div className="msg-box" > 
                                                      
          <div className="msg-icon">
            { props.status==='Delivered'?
              <MarkEmailUnreadIcon sx={{ color: "red",fontSize:30}} />:
              props.status==='Read'?
              <MarkEmailReadIcon sx={{color:"green",fontSize:30}} />:
              props.status==='Sent'?
              <ReplyIcon sx={{color:"green",fontSize:30}} />:
              null
            }
          </div>

          <div className="msg-dtls">
            {props.name}
          </div>

          <div className="msg-dtls">
            {props.subject}
          </div>

          <div className="msg-dtls">
            {props.email}
          </div>
                    
          <div className="msg-date">
            [ {props.date} ]
          </div>
                                                                    
          <div  className="btn-container">                 
            <button type="button" 
              className="btn btn-warning btn-sm"                
              onClick={toggleMessage}
            >
              הצג הודעה
            </button> 
          </div>

      </div>
                                                      
      {flag?                                  
        <div className="msg-viewer">               
          <DisplayMsg 
            index={props.index}
            msg={props.msg} 
            email={props.email} 
            subject={props.subject} 
            msgId={props.msgId}
            status={props.status}
            updateMessage={props.updateMessage} 
            updateMsgStatus={props.updateMsgStatus}
            date={props.date}
            alert={props.alert}
          />
        </div>                                                           
        :null
      }

    </div>                                
  );
};

export default MessageBox;
