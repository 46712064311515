import React from "react";
import { storage } from "./firebase";
import { ref, getDownloadURL } from "firebase/storage";

const Help = (props) => {

  const openHelp = () => {
    if (props.help !== "") {
      const fileRef = ref(storage, `guides/${props.help}`);
      getDownloadURL(fileRef)
        .then((url) => {
          window.open(url, '_blank'); // Open in a new tab
        })
        .catch((error) => {
          props.alert("Error loading PDF: " + error.message,'ERROR');
        });
    }
  };

  return (
    <div>
      <button 
        type="button" 
        className="btn btn-link"
        onClick={openHelp} 
        disabled={props.help === ""}
        style={{ 
          outline: 'none', 
          boxShadow: 'none',
          border: 'none', 
          textDecoration: 'none' ,
        }}
      >
        {props.btn_txt}
      </button>
    </div>
  );
};

export default Help;
